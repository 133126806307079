import { Popover } from "@headlessui/react";
import { ChangeEvent, useContext, useState } from "react";
import { ReferralContext } from "../../context/ReferralContext";
import { ExpandLess, ExpandMore, FilterAltOutlined } from "@mui/icons-material";
import SimpleButton from "./Button";

interface FilterOption {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  searchable?: boolean;
}

type FilterComponentProps = {
  filterOptions: FilterOption[];
  filters: { [key: string]: string | string[] };
  handleFilterChange: (filters: { [key: string]: string | string[] }) => void;
};

const FilterComponent: React.FC<FilterComponentProps> = ({
  filterOptions,
  filters,
  handleFilterChange,
}) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const [expandedFilter, setExpandedFilter] = useState<string | null>(null);
  const [searchTerms, setSearchTerms] = useState<{ [key: string]: string }>({});

  const toggleFilter = (name: string) => {
    setExpandedFilter(expandedFilter === name ? null : name);
  };

  const onFilterChange = (name: string, value: string, checked: boolean) => {
    const currentFilter = localFilters[name] || [];
    const updatedFilter = checked
      ? [
          ...(Array.isArray(currentFilter) ? currentFilter : [currentFilter]),
          value,
        ] // Add the value
      : Array.isArray(currentFilter)
      ? currentFilter.filter((v: string) => v !== value)
      : []; // Remove the value

    setLocalFilters({ ...localFilters, [name]: updatedFilter });
  };

  const handleResetFilters = () => {
    setLocalFilters({});
    setSearchTerms({});
    handleFilterChange({});
  };

  const handleSearchChange = (
    name: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerms({ ...searchTerms, [name]: e.target.value });
  };

  const filterOptionsBySearch = (
    name: string,
    options: { value: string; label: string }[]
  ) => {
    const searchTerm = searchTerms[name] || "";
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const getSelectedCount = (name: string) => {
    const selectedItems = localFilters[name] || [];
    return Array.isArray(selectedItems) ? selectedItems.length : 0;
  };

  return (
    <Popover id={`desktop-menu`} className="relative inline-block text-left">
      <Popover.Button className="group inline-flex h-full items-center rounded gap-2 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-grayscale-300 focus:z-10">
        <FilterAltOutlined fontSize="small" /> Filter{" "}
        {Object.keys(filters).filter(
          (key) => Array.isArray(filters[key]) && filters[key].length > 0
        ).length > 0 && (
          <span className="text-xs text-gray-500 bg-p1 w-4 h-4 rounded flex items-center justify-center">
            {
              Object.keys(filters).filter(
                (key) => Array.isArray(filters[key]) && filters[key].length > 0
              ).length
            }
          </span>
        )}
      </Popover.Button>
      <Popover.Panel className="absolute z-50 mt-2 w-72 origin-top-right rounded-md bg-white pt-4 pb-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none">
        <h3 className="border-b border-gray-200 px-4 pb-2">Choose Filters</h3>
        <form className="px-4 divide-y divider-gray-200 h-fit">
          {filterOptions.map((option) => (
            <div key={option.name} className="py-2">
              <label
                className="flex text-gray-700 cursor-pointer gap-4 justify-between w-full"
                onClick={() => toggleFilter(option.name)}
              >
                <div className="flex gap-2 items-center">
                  {option.label}{" "}
                  {getSelectedCount(option.name) > 0 && (
                    <span className="text-xs text-gray-500 bg-p1 w-4 h-4 rounded flex items-center justify-center">
                      {getSelectedCount(option.name)}
                    </span>
                  )}
                </div>
                {expandedFilter === option.name ? (
                  <ExpandMore fontSize="small" />
                ) : (
                  <ExpandLess fontSize="small" />
                )}
              </label>
              {expandedFilter === option.name && (
                <>
                  {option.searchable && (
                    <input
                      type="text"
                      placeholder="Search..."
                      className="w-full py-1 px-3 mt-2 mb-1 border rounded border-gray-200"
                      value={searchTerms[option.name] || ""}
                      onChange={(e) => handleSearchChange(option.name, e)}
                    />
                  )}
                  <div className="border rounded divide-y">
                    {option.options ? (
                      <div className="divide-y">
                        {filterOptionsBySearch(option.name, option.options).map(
                          (opt) => (
                            <div
                              key={opt.value}
                              className="flex items-center p-1"
                            >
                              <input
                                type="checkbox"
                                id={`${option.name}-${opt.value}`}
                                name={option.name}
                                value={opt.value}
                                checked={localFilters[option.name]?.includes(
                                  opt.value
                                )}
                                onChange={(e) => {
                                  onFilterChange(
                                    option.name,
                                    opt.value,
                                    e.target.checked
                                  );
                                }}
                                className="mr-2 rounded"
                              />
                              <label
                                htmlFor={`${option.name}-${opt.value}`}
                                className="text-gray-700"
                              >
                                {opt.label}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div>No Options</div>
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
          <div className="flex w-full justify-evenly gap-2 pt-2">
            <SimpleButton
              styleString="secondary"
              overrideStyles="flex-1"
              children="Reset"
              onClick={handleResetFilters}
            />
            <SimpleButton
              overrideStyles="flex-1"
              children="Apply"
              onClick={() => handleFilterChange(localFilters)}
            />
          </div>
        </form>
      </Popover.Panel>
    </Popover>
  );
};

export default FilterComponent;
