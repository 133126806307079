import React, { useState, useEffect } from "react";
import { FilterAltOutlined } from "@mui/icons-material";
import DischargeTable from "../components/tables/DischargeTable";
import axios from "axios";
import { useNavigate } from "react-router";
import { useSession } from "../hooks/SessionHook";
import { Resident } from "../types/Resident";
import CardContainer from "../components/container/CardContainer";
import PageHeader from "../components/general-components/PageHeader";
import FilterComponent from "../components/general-components/Filter";
import { useReferral } from "../hooks/ReferralHook";

const Archives: React.FC = () => {
  const {
    residents,
    handleView,
    appendResidents,
    moreItems,
    users,
    loading,
    filters,
    handleFilterChange,
    initializeData,
  } = useReferral();

  const statuses = ["INACTIVE"];
  
  useEffect(() => {
    initializeData(statuses);
  }, []);

  const filterOptions = [
    {
      name: "resident",
      label: "Resident",
      options: residents.map((resident) => ({
        value: resident._id,
        label: resident.first_name + " " + resident.last_name,
      })),
      searchable: true,
    },
    {
      name: "assignee",
      label: "Assignee",
      options: users.map((user) => ({
        value: user._id,
        label: user.family_name + " " + user.given_name,
      })),
      searchable: true,
    },
    {
      name: "status",
      label: "Status",
      options: [
        { value: "Not Started", label: "Not Started" },
        { value: "Pending", label: "In Progress" },
        { value: "Complete", label: "Completed" },
      ],
      searchable: false,
    },
    {
      name: "discharge_date",
      label: "Discharge Date",
      options: [
        { value: "today", label: "Today" },
        { value: "this-week", label: "This Week" },
        { value: "this-month", label: "This Month" },
        { value: "this-year", label: "This Year" },
      ],
      searchable: false,
    },
  ];

  return (
    <>
      <CardContainer>
        <PageHeader title="Archives" />
        <div className="flex w-full justify-between my-2">
          <FilterComponent
            filterOptions={filterOptions}
            filters={filters}
            handleFilterChange={handleFilterChange}
          />
        </div>
        <DischargeTable
          residents={residents}
          handleView={handleView}
          handleLoadMore={() => appendResidents(statuses)}
          isMoreItems={moreItems}
          loading={loading}
        />
      </CardContainer>
    </>
  );
};

export default Archives;
