import React from "react";

interface BadgeProps {
  badge: {
    name: string;
    status?: string;
  };
  display?: string;
}

const Badge: React.FC<BadgeProps> = ({ badge, display = badge.name }) => {
  let textStyle: string;
  let boxStyle: string;
  switch (badge.status?.toLowerCase()) {
    case "complete":
    case "accepted":
      boxStyle = "fill-success";
      break;
    case "pending":
      boxStyle = "fill-warning";
      break;
    case "in progress":
    case "incomplete":
      boxStyle = "fill-r";
      break;
    case "rejected":
      boxStyle = "fill-error";
      break;
    default:
      boxStyle = "fill-info";
  }

  return (
    <span className="inline-flex items-center gap-x-1.5 rounded px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
      <svg aria-hidden="true" className={`h-3 w-3 rounded ${boxStyle}`}>
        <rect width={12} height={12} rx={1} ry={1} />
      </svg>

      <span className={``}>{display}</span>
    </span>
  );
};

export default Badge;
