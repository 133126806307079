import React from "react";
import { UseFormRegister } from "react-hook-form";

interface SimpleInputProps {
  label: string;
  register: UseFormRegister<any>;
  fieldName: string;
  disabled?: boolean;
  type?: string;
  error?: string;
}

const SimpleInput: React.FC<SimpleInputProps> = ({
  label,
  register,
  fieldName,
  disabled = false,
  type = "text",
  error,
}) => {
  return (
    <>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <input
        type={type}
        disabled={disabled}
        className={`form-input block w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ${
          error
            ? "ring-red-500 focus:ring-red-500"
            : "ring-gray-100 focus:ring-2 focus:ring-s1-100"
        } sm:text-sm sm:leading-6 disabled:bg-gray-50 disabled:cursor-not-allowed`}
        {...register(fieldName)}
      />
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </>
  );
};

export default SimpleInput;
