import React from "react";
import { useSocket } from "../../context/ChatContext";
import { ChatBubbleOutline } from "@mui/icons-material";

type ChatIconProps = {
  chatId: string;
};

const ChatIcon: React.FC<ChatIconProps> = ({ chatId }) => {
  const { unreadMessages } = useSocket();
  return (
    <div className="text-p3-100 rounded relative flex justify-end mx-1 my-2">
      <ChatBubbleOutline fontSize="small" />
      {unreadMessages[chatId] !== undefined && unreadMessages[chatId] > 0 && (
        <span className="text-xs flex items-center justify-center text-p1 bg-s1-100 rounded-full h-3 w-3 absolute -top-2 -right-1 z-30">
          {unreadMessages[chatId]}
        </span>
      )}
    </div>
  );
};

export default ChatIcon;
