import React, { useState, useEffect } from "react";
import { Person } from "@mui/icons-material";
import Tabs from "../components/general-components/Tabs";
import TerminationTable from "../components/tables/TerminationTable";
import { useSession } from "../hooks/SessionHook";
import axios from "axios";
import CardContainer from "../components/container/CardContainer";

interface Tab {
  name: string;
  value: string;
  current: boolean;
  icon: React.ComponentType<{ className: string }>;
}

const Terminations: React.FC = () => {
  const { currentOrganization, user } = useSession();

  const [terminatedRequests, setTerminatedRequests] = useState<any[]>([]);
  const [requestType, setRequestType] = useState<string>("CHHA");

  const [tabs, setTabs] = useState<Tab[]>([
    { name: "CHHA", value: "CHHA", icon: Person, current: true },
    { name: "LHCSA", value: "LHCSA", icon: Person, current: false },
    { name: "DME", value: "DME", icon: Person, current: false },
    { name: "RPM", value: "RPM", icon: Person, current: false },
    { name: "PHARMACY", value: "PHARMACY", icon: Person, current: false },
  ]);

  // Tab change handler
  const handleTabChange = (selectedTab: string): void => {
    setRequestType(selectedTab.toUpperCase());
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.name === selectedTab,
      }))
    );
  };

  useEffect(() => {
    if (!currentOrganization) return;

    fetchTerminatedServiceRequests(requestType);
  }, [requestType, currentOrganization]);

  const fetchTerminatedServiceRequests = async (
    providerType: string
  ): Promise<void> => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) return;
      const response = await axios.get(
        `/api/terminations/list/${providerType}/${currentOrganization?._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      setTerminatedRequests(response.data);
    } catch (error) {
      console.error("Failed to fetch terminated requests: ", error);
    }
    // setTerminatedRequests(response.data);
  };

  return (
    <CardContainer>
      <Tabs tabs={tabs} onChange={handleTabChange} />
      <TerminationTable terminations={terminatedRequests} />
    </CardContainer>
  );
};

export default Terminations;
