import { SetURLSearchParams } from "react-router-dom";
import { updateSearchParams } from "../utils/ParamUtils";
import { Resident } from "../types/Resident";
import { changeDateHelper } from "../utils/Helpers";

export const handleView = (
  resident: Resident,
  route: string = "referrals",
  navigate: (to: string) => void
): void => {
  navigate(`${route}/${resident._id}`);
};

export const handleFilterChange = (
  newFilters: { [key: string]: any },
  setSearchParams: SetURLSearchParams
) => {
  updateSearchParams(setSearchParams, {
    filters: JSON.stringify(newFilters),
  });
};

// Handles changing the date with parameters and direction logic
export const changeDate = (
  date: string,
  direction: "forward" | "backward" | "reset",
  setSearchParams: SetURLSearchParams
) => {
  const { newCurrentDate, startDate, endDate } = changeDateHelper(
    date,
    direction
  );

  updateSearchParams(setSearchParams, {
    view: "calendar",
    date: newCurrentDate,
    start: startDate,
    end: endDate,
  });
};

export const handlePageChange = (
  newPage: number,
  setSearchParams: SetURLSearchParams
) => {
  updateSearchParams(setSearchParams, {
    page: newPage.toString(),
  });
};

export const handleViewChange = (
  newView: "list" | "calendar",
  setSearchParams: SetURLSearchParams
) => {
  updateSearchParams(setSearchParams, {
    view: newView,
  });
};

