import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useSession } from "../hooks/SessionHook";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useSocket } from "./ChatContext";
import { ServiceRequest } from "../types/ServiceRequest";
import { set } from "react-hook-form";
import {
  fetchRequest,
  fetchRequests,
} from "../api/services/serviceRequestService";
import {
  fetchDocument,
  fetchExternalResident,
} from "../api/services/residentService";
import { handleFilterChange, handleView } from "./ProviderHelper";
import { Organization } from "../types/Organization";
import { fetchLinkedOrganizations } from "../api/services/organizationService";

interface TerminateFormValues {
  requestId: string;
  service_end_date: Date;
  termination_notes?: string;
}
interface AcceptFormValues {
  requestId: string;
  start_date: Date;
  decision: string;
}

type ProviderContextType = {
  serviceRequests: ServiceRequest[];
  selectedRequest: ServiceRequest | null;
  onAcceptSubmit: (data: AcceptFormValues) => void;
  onRejectSubmit: (requestId: string) => void;
  onTerminateSubmit: (data: TerminateFormValues) => void;
  modalState: {
    showAccept: boolean;
    showReject: boolean;
    showTerminate: boolean;
    showFiles: boolean;
  };
  handleModalToggle: (modalName: ShowOptions, isOpen: boolean) => void;
  loading: boolean;
  handleView: (requestId: string, route: string) => void;
  filters: { [key: string]: string[] };
  handleFilterChange: (newFilters: { [key: string]: any }) => void;
  handleLoadMore: (status: string) => void;
  moreItems: boolean;
  status: string;
  externalResident: any | null;
  loadingExternalResident: boolean;
  fetchDocument: (residentId: string, fileType: string) => void;
  initializeData: (status: string) => void;
  linkedFacilities: Organization[];
};

type ShowOptions = "showAccept" | "showReject" | "showTerminate" | "showFiles";

// Create the context
export const ProviderContext = createContext<ProviderContextType>(
  {} as ProviderContextType
);

interface ProviderContextProviderProps {
  children: React.ReactNode;
}
// Create the ProviderContext component
const ProviderContextProvider: React.FC<ProviderContextProviderProps> = ({
  children,
}) => {
  const { currentOrganization, user } = useSession();
  const { socket } = useSocket();
  const navigate = useNavigate();

  const [moreItems, setMoreItems] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<number>(1);

  const [loading, setLoading] = useState<boolean>(true);

  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>([]);

  const [modalState, setModalState] = useState({
    showAccept: false,
    showReject: false,
    showTerminate: false,
    showFiles: false,
  });

  const [selectedRequest, setSelectedRequest] = useState<ServiceRequest | null>(
    null
  );

  const [loadingExternalResident, setLoadingExternalResident] = useState(true);
  const [externalResident, setExternalResident] = useState<any | null>(null);

  const { id, status } = useParams();

  const memoizedSearchParams = useMemo(
    () => ({
      startDate: searchParams.get("start") || "",
      endDate: searchParams.get("end") || "",
      date: searchParams.get("date") || moment().format("YYYY-MM-DD"),
      filters: JSON.parse(searchParams.get("filters") || "{}"),
    }),
    [searchParams]
  );

  const fetchServiceRequests = useCallback(
    async (status: string, reset: boolean, page: number) => {
      if (!currentOrganization || !user || !user._id) return;
      try {
        setLoading(true);
        const params = {
          filters: memoizedSearchParams.filters,
          page: page.toString(),
        };

        const { serviceRequests, hasMore } = await fetchRequests(
          currentOrganization._id,
          status,
          params
        );

        if (reset) {
          setServiceRequests(serviceRequests); // Replace requests when initializing or reloading
        } else {
          setServiceRequests((prev) => [...prev, ...serviceRequests]); // Append residents for pagination
        }
        setMoreItems(hasMore);
        setLoading(false);
      } catch (error) {
        console.log("error fetching service requests:", error);
      }
    },
    [currentOrganization, status, user, memoizedSearchParams]
  );

  const initializeData = useCallback(
    async (status: string) => {
      if (!status) return;
      setPage(1);
      setServiceRequests([]); // Clear current requests
      await fetchServiceRequests(status.toUpperCase(), true, 1); // Fetch requests with reset
      await loadFacilities();
    },
    [fetchServiceRequests]
  );

  const handleLoadMore = async (status: string) => {
    if (!loading && moreItems) {
      setPage((prev) => prev + 1);
      if (page > 1) {
        await fetchServiceRequests(status, false, page);
      }
    }
  };

  const [linkedFacilities, setLinkedFacilities] = useState<Organization[]>([]);

  const loadFacilities = async () => {
    if (!currentOrganization) return;
    const fetchedOrgs = await fetchLinkedOrganizations(currentOrganization._id);
    setLinkedFacilities(fetchedOrgs);
  };
  // Fetch the selected request
  useEffect(() => {
    let isMounted = true;
    const fetchRequestData = async () => {
      if (id) {
        const request = await fetchRequest(id);
        if (request) {
          setSelectedRequest(request);
          onFetchExternalResident(request._id);
        }
      }
    };
    fetchRequestData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const onFetchExternalResident = async (requestId: string) => {
    const residentData = await fetchExternalResident(requestId);

    setExternalResident(residentData);
    setLoadingExternalResident(false);
  };

  const onAcceptSubmit = async (data: AcceptFormValues) => {
    try {
      if (!socket) return;
      socket.emit("update_request_status", {
        ...data,
      });
      handleModalToggle("showAccept", false);
      navigate(`/requests/pending`);
    } catch (error) {
      console.error(error);
    }
  };

  const onRejectSubmit = async (requestId: string) => {
    try {
      if (!socket) return;
      socket.emit("update_request_status", {
        requestId,
        decision: "REJECTED",
      });
      handleModalToggle("showReject", false);
      navigate(`/requests/pending`);
    } catch (error) {
      console.error(error);
    }
  };

  const onTerminateSubmit = async (data: TerminateFormValues) => {
    try {
      if (!socket) return;
      socket.emit("terminate_request", data);
      handleModalToggle("showTerminate", false);
      navigate(`/requests/accepted`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalToggle = (modalName: ShowOptions, isOpen: boolean) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalName]: isOpen,
    }));
  };

  return (
    <ProviderContext.Provider
      value={{
        serviceRequests,
        selectedRequest,
        onAcceptSubmit,
        onRejectSubmit,
        onTerminateSubmit,
        modalState,
        handleModalToggle,
        loading,
        loadingExternalResident,
        handleView: (requestId, route) =>
          handleView(requestId, route, navigate),
        handleFilterChange: (filters) =>
          handleFilterChange(filters, setSearchParams),
        handleLoadMore,
        moreItems,
        status: status || "pending",
        externalResident,
        fetchDocument: (residentId: string, fileType: string) =>
          fetchDocument(residentId, fileType),
        ...memoizedSearchParams,
        initializeData,
        linkedFacilities,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export default ProviderContextProvider;
