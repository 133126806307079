import { ChevronLeft } from "@mui/icons-material";
import { useSocket } from "../../context/ChatContext";
import ChatList from "./ChatList";
import { Fragment } from "react/jsx-runtime";
import MessageHistory from "./MessageHistory";
import { useCallback, useEffect, useRef, useState } from "react";
import { Message } from "../../types/Message";
import InputSection from "./InputSection";
import { useSession } from "../../hooks/SessionHook";

interface ChatContainerProps {}

export default function ChatContainer({}: ChatContainerProps) {
  const { user } = useSession();
  const { selectedChat, setSelectedChat, socket, setUnreadMessages } =
    useSocket();

  const [messageHistory, setMessageHistory] = useState<Message[]>([]);
  const messageContainerRef = useRef<HTMLDivElement>(null);

  // Scroll Utilities
  const scrollToBottom = () => {
    const container = messageContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  // Handle scrolling behavior when fetching messages
  const maintainScrollPosition = useCallback(
    (previousScrollHeight: number, previousScrollTop: number) => {
      const container = messageContainerRef.current;
      if (container) {
        const newScrollHeight = container.scrollHeight;
        const heightDiff = newScrollHeight - previousScrollHeight;
        container.scrollTop = previousScrollTop + heightDiff;
      }
    },
    []
  );
  // Fetching and handling messages
  const fetchMoreMessages = async () => {
    if (!socket || !messageContainerRef.current || !selectedChat) return;

    const params = {
      chatRoom: selectedChat,
      lastLoadedMessageTimeStamp: messageHistory[0]?.createdAt,
    };

    socket.emit("fetch_more_messages", params);
  };
  // Scroll Event Handler
  const handleScroll = () => {
    const container = messageContainerRef.current;
    if (container && container.scrollTop === 0) {
      fetchMoreMessages();
    }
  };
  // Socket event listeners for message handling
  useEffect(() => {
    if (!socket || !selectedChat) return;

    const requestId = selectedChat._id;

    socket.emit("join_room", requestId);

    const handleInitialMessages = (messages: Message[]) => {
      setMessageHistory(messages);
      setUnreadMessages((prevUnreadMessages) => {
        const newUnreadMessages = { ...prevUnreadMessages };
        delete newUnreadMessages[requestId];
        return newUnreadMessages;
      });
      setTimeout(scrollToBottom, 100);
    };

    const handleMessage = ({
      message,
      chatRoomId,
    }: {
      message: Message;
      chatRoomId: string;
    }) => {
      if (chatRoomId !== selectedChat._id) {
        console.log("Message received from another chat room");
        return;
      }

      setMessageHistory((prevMessages) => [...prevMessages, message]);

      if (!messageContainerRef.current) return;

      const container = messageContainerRef.current;
      const tolerance = 5;
      const isAtBottom =
        Math.abs(
          container.scrollHeight - container.scrollTop - container.clientHeight
        ) <= tolerance;

      if (isAtBottom || message.author._id === user?._id) {
        setTimeout(scrollToBottom, 100);
      }
    };

    const handleMessageHistory = (messages: Message[]) => {
      setMessageHistory((currentMessages) => [...messages, ...currentMessages]);

      // Ensure scroll position is maintained after messages are added
      const container = messageContainerRef.current;
      if (container) {
        const previousScrollHeight = container.scrollHeight;
        const previousScrollTop = container.scrollTop;
        setTimeout(() => {
          maintainScrollPosition(previousScrollHeight, previousScrollTop);
        }, 100);
      }
    };

    socket.on("initial_messages", handleInitialMessages);
    socket.on("received_message", handleMessage);
    socket.on("message_history", handleMessageHistory);

    return () => {
      socket.emit("leave_room", requestId);
      socket.off("initial_messages", handleInitialMessages);
      socket.off("received_message", handleMessage);
      socket.off("message_history", handleMessageHistory);
    };
  }, [socket, selectedChat, user, setUnreadMessages]);

  return (
    <>
      {!selectedChat ? (
        <ChatList />
      ) : (
        <div className="flex h-full  flex-col divide-y divide-gray-200 bg-white w-full">
          <div className="flex h-16 items-center p-4 w-full border-b">
            <button onClick={() => setSelectedChat(null)} className="mr-3">
              <ChevronLeft />
            </button>
            <div className="flex justify-center items-center w-8 h-8 bg-s1-100 text-white rounded-full mr-2">
              {selectedChat.service_request.resident.first_name[0] +
                selectedChat.service_request.resident.last_name[0]}
            </div>

            <div className="flex flex-col flex-grow overflow-hidden">
              <span className="mr-2">
                {selectedChat.service_request.provider_organization?.name ??
                  "Unknown Organization"}
              </span>
              <div className="flex overflow-hidden items-center">
                {selectedChat.participants?.map((participant, index) => (
                  <Fragment key={participant.user?._id ?? `unknown-${index}`}>
                    <div className="text-xs font-light whitespace-nowrap overflow-hidden text-ellipsis">
                      {participant.user?.given_name ?? "Unknown"}{" "}
                      {participant.user?.family_name ?? "User"}
                    </div>
                    {index < selectedChat.participants.length - 1 && (
                      <span>,&nbsp;</span>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
          <div
            className="flex-grow py-2 px-4 sm:px-6 overflow-y-auto scrollbar-thin"
            onScroll={handleScroll}
            ref={messageContainerRef}
          >
            <MessageHistory messages={messageHistory} />
          </div>
          <div className="flex flex-shrink-0 justify-center px-4 py-4 align-middle">
            <InputSection chatRoom={selectedChat} />
          </div>
        </div>
      )}
    </>
  );
}
