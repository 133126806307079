import React from "react";
import { formatDate } from "../../utils/Format";
import type { Message } from "../../types/Message";
import { useSession } from "../../hooks/SessionHook";

interface MessageProps {
  message: Message;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const { user } = useSession();

  if (!user) return null;

  return message.message_type !== "ACTION" ? (
    <div key={message._id} className="my-2 ">
      <div
        className={`px-4 pt-2 pb-6 rounded border border-grayscale-300 w-fit  flex flex-col relative max-w-[55%] min-w-[35%] ${
          message?.author._id === user._id ? " mr-auto bg-grayscale-200" : " ml-auto bg-grayscale-100"
        }`}
      >
        <div>
          <p className="text-xs font-semibold text-s1">
            {message.author.given_name} {message.author.family_name}
          </p>
          <p className="pt-1 leading-tight text-sm text-text break-words">
            {message.content}
          </p>
        </div>
        <span className="text-[9px] text-text-300 absolute bottom-2 right-4">
          {formatDate(message.createdAt, "chat", "utc")}
        </span>
      </div>
    </div>
  ) : (
    <div className="w-full font-lato flex flex-wrap justify-center text-[12px] text-text-400 font-light  items-end align-text-bottom">
      <span className="font-semibold">
        {message.author.given_name} {message.author.family_name}
      </span>
      &nbsp;
      <span>{message.content}</span>
      &nbsp;
      <p className="text-text">{formatDate(message.createdAt, "chat")}</p>
    </div>
  );
};

interface MessageHistoryProps {
  messages: Message[];
}

const MessageHistory: React.FC<MessageHistoryProps> = ({ messages }) => {
  return (
    <>
      {messages.map((message) => (
        <Message key={message._id} message={message} />
      ))}
    </>
  );
};

export default MessageHistory;
