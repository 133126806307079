import {
  ChatBubbleOutlineOutlined,
  DeleteOutlineOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { formatDate } from "../utils/Format";
import Badge from "../components/general-components/Badges";
import NewServiceRequestForm from "../components/forms/NewServiceRequestForm";
import CancelModal from "../components/modals/CancelModal";
import type { ServiceRequest } from "../types/ServiceRequest";
import { Resident } from "../types/Resident";
import type { ServiceTypes } from "../types/ServiceRequest";
import { useSocket } from "../context/ChatContext";
import SimpleButton from "../components/general-components/Button";
import CardContainer from "../components/container/CardContainer";
import FooterContainer from "../components/container/FooterContainer";
import ChatIcon from "../components/chat/ChatIcon";
import OverlayModal from "../components/modals/OverlayModal";
import AddServiceTypeForm from "../components/forms/AddServiceTypeForm";
import { useResident } from "../hooks/ResidentContext";

interface ServiceRequestCount {
  items: ServiceRequest[];
  status: string;
}

type CategorizedServiceRequests = Partial<{
  [key in ServiceTypes]: ServiceRequestCount;
}>;

const ViewResident: React.FC = () => {
  const {
    resident,
    deleteServiceRequest,
    toggleActiveStatus,
    deleteResident,
    removeType,
    addType,
    loading,
    fetchDocument,
  } = useResident();

  const { selectChatById, setShowChat } = useSocket();

  const [serviceRequests, setServiceRequests] =
    useState<CategorizedServiceRequests>({
      CHHA: { items: [], status: "Not Started" },
      LHCSA: { items: [], status: "Not Started" },
      DME: { items: [], status: "Not Started" },
      RPM: { items: [], status: "Not Started" },
      PHARMACY: { items: [], status: "Not Started" },
    });

  useEffect(() => {
    if (
      !resident ||
      !resident.service_requests ||
      !resident.required_request_types
    )
      return;
    const reformattedData = groupByRequestType(
      resident.service_requests,
      resident.required_request_types
    );
    setServiceRequests(reformattedData);
  }, [resident]);

  function groupByRequestType(
    data: ServiceRequest[],
    requiredTypes: ServiceTypes[]
  ) {
    // Object to hold the groups
    const grouped: CategorizedServiceRequests = {};

    // Initialize the grouped object with required types
    requiredTypes.forEach((type) => {
      grouped[type] = { items: [], status: "Not Started" };
    });

    // Iterate over each item in the data array
    data.forEach((item) => {
      const { service_type } = item;

      if (grouped.hasOwnProperty(service_type)) {
        grouped[service_type]!.items.push(item);

        if (item.status === "ACCEPTED") {
          grouped[service_type]!.status = "Complete";
        } else if (grouped[service_type]!.status !== "Complete") {
          grouped[service_type]!.status = "In Progress";
        }
      }
    });

    return grouped;
  }

  // Resident delete state management
  const [showResidentDeleteRequest, setShowResidentDeleteRequest] =
    useState<boolean>(false);
  // Show the delete resident modal
  const handleResidentDeleteRequest = (): void => {
    setShowResidentDeleteRequest(true);
  };
  // Handle the delete resident action
  const deleteResidentAction = (resident: Resident): void => {
    deleteResident(resident);
    window.history.back();
  };

  // New service request state management
  const [createRequest, setCreateRequest] = useState<boolean>(false);
  const [providerType, setProviderType] = useState<ServiceTypes | "">("");
  const handleNewRequest = (providerType: ServiceTypes) => {
    setCreateRequest(true);
    setProviderType(providerType);
  };

  // Service Request delete state management
  const [showDeleteRequest, setShowDeleteRequest] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<ServiceRequest | null>(
    null
  );
  // Show the delete request modal
  const handleDeleteRequest = (request: ServiceRequest): void => {
    setSelectedRequest(request);
    setShowDeleteRequest(true);
  };
  // Delete the selected service request and hide the modal
  const deleteRequest = (request: ServiceRequest | null): void => {
    deleteServiceRequest(selectedRequest);
    setShowDeleteRequest(false);
  };

  //  Show the selected chat
  const onChatClick = (serviceRequest: ServiceRequest) => {
    selectChatById(serviceRequest.chat_room._id);
    setShowChat(true);
  };

  const requests = (type: ServiceTypes) => {
    return (serviceRequests[type]?.items ?? []).map(
      (request: ServiceRequest) => (
        <div key={request._id} className="ml-6">
          <h2 className="text-lg my-2 text-gray-900 font-semibold">
            {request.provider_organization
              ? request.provider_organization.name
              : "No provider"}
          </h2>
          <div className="absolute right-4 flex gap-2">
            {/* Caught a bug here */}
            {request.provider_organization && request.chat_room && (
              <button onClick={() => onChatClick(request)}>
                <ChatIcon chatId={request.chat_room._id} />
              </button>
            )}
            {request.status === "PENDING" && (
              <button
                onClick={() => handleDeleteRequest(request)}
                className=" text-red-700"
              >
                <DeleteOutlineOutlined fontSize="small" />
              </button>
            )}
          </div>
          <h2 className="text-s1 text-sm my-2">
            Status:{" "}
            {
              <Badge
                badge={{
                  name: request.provider_organization.name,
                  status: request.status,
                }}
                display={request.status}
              />
            }
          </h2>

          <h2 className="text-s1 text-sm my-2">
            Date Requested:{" "}
            <span className="text-gray-600 text-xs font-semibold">
              {formatDate(request.date_created, "MM/DD/YYYY")}
            </span>
          </h2>
          {request.status === "ACCEPTED" && request.service_start_date && (
            <h2 className="text-s1 text-sm my-2">
              Start Date:{" "}
              <span className="text-gray-600 text-xs font-semibold">
                {formatDate(request.service_start_date, "MM/DD/YYYY")}
              </span>
            </h2>
          )}
        </div>
      )
    );
  };

  const emptyRequests = (type: ServiceTypes) => {
    return (
      <div key={type}>
        <h2 className="italic text-sm  text-gray-600 text-center py-4">
          No Active Requests
        </h2>
      </div>
    );
  };

  const [showRequestType, setShowRequestType] = useState<ServiceTypes[] | null>(
    []
  );
  const handleShowRequestType = (type: ServiceTypes) => {
    if (showRequestType && showRequestType.includes(type)) {
      setShowRequestType(showRequestType.filter((t) => t !== type));
    } else {
      setShowRequestType([...(showRequestType || []), type]);
    }
  };

  const [showNewType, setShowNewType] = useState<boolean>(false);

  const handleAddRequestType = () => {
    setShowNewType(true);
  };

  const handleNewTypeSubmit = (data: { newTypes: ServiceTypes[] }) => {
    addType(data);
    setShowNewType(false);
  };

  return (
    <div className="box-border h-full flex flex-col">
      <button
        className="mx-4 my-2 self-start text-gray-500 text-sm flex items-center hover:shadow-sm"
        onClick={() => window.history.back()}
      >
        <ExpandLess fontSize="small" className="-rotate-90" />
        <span>
          Back to{" "}
          {window.location.pathname.startsWith("/referrals")
            ? "Referrals"
            : "Archives"}
        </span>
      </button>
      {resident && !loading ? (
        <>
          <CardContainer>
            <h1 className="text-2xl text-gray-600 font-semibold border-b border-acc">
              {resident.first_name} {resident.last_name}
            </h1>
            <div className="w-full xl:grid-cols-2 py-4 grid border-b border-gray-500 mb-4">
              <div className="grid grid-cols-2 w-96">
                <span className="text-gray-800 font-semibold ">Status: </span>
                <span className="text-md text-gray-500 ">
                  <Badge
                    badge={{
                      name: resident.overall_status,
                      status: resident.overall_status,
                    }}
                  />
                </span>
                <span className="text-gray-800 font-semibold ">
                  Date Created:{" "}
                </span>
                <span className="text-md text-gray-500">
                  {formatDate(resident.date_created, "MM/DD/YYYY", "utc")}
                </span>
                <span className="text-gray-800 font-semibold">
                  Discharge Date:{" "}
                </span>
                <span className="text-md text-gray-500">
                  {formatDate(resident.discharge_date, "MM/DD/YYYY", "utc")}
                </span>
                <span className="text-gray-800 font-semibold">Files: </span>
                {
                  <div className="flex flex-col">
                    <button
                      onClick={() => fetchDocument(resident._id, "face_sheet")}
                      className="w-fit text-md text-p2-100 underline"
                    >
                      Face Sheet
                    </button>
                  </div>
                }
                  <span className="text-gray-800 font-semibold ">
                    Assigned to:{" "}
                  </span>

                  {!resident.discharge_coordinators ||
                  resident.discharge_coordinators.length === 0 ? (
                    <div className="text-xs">Unassigned</div>
                  ) : (
                    resident.discharge_coordinators?.map((coordinator) => (
                      <div
                        key={coordinator?.user._id}
                        className="flex items-start gap-2"
                      >
                        <div className="flex justify-center items-center w-6 h-6 bg-s1-100 text-white rounded-full">
                          {coordinator?.user.given_name.charAt(0).toUpperCase()}
                        </div>
                        <span>
                          {coordinator?.user.given_name}{" "}
                          {coordinator?.user.family_name}
                        </span>
                      </div>
                    ))
                  )}
              </div>

            </div>
            <div className="flex w-full justify-end">
              <SimpleButton
                icon="Add"
                children="Add Service Type"
                styleString="minimal"
                onClick={handleAddRequestType}
                overrideStyles=""
              />
            </div>
            <div className=" flex flex-col gap-2 overflow-auto max-h-[470px] relative scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-p1-100 pr-2">
              {Object.entries(serviceRequests).map(([type, request], index) => (
                <div
                  key={`${resident._id}-${type}-${index}`}
                  className="flex flex-col w-full"
                >
                  <div
                    className="flex w-full justify-between pb-2 border-b border-gray-300 cursor-pointer "
                    onClick={() => handleShowRequestType(type as ServiceTypes)}
                  >
                    <h2 className="text-gray-600 font-bold text-lg">{type} </h2>
                    {request.items.length > 0 ? (
                      <div className="flex gap-2 items-center">
                        {showRequestType &&
                        showRequestType.includes(type as ServiceTypes) ? (
                          <>
                            <SimpleButton
                              children="Create Request"
                              styleString="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleNewRequest(type as ServiceTypes);
                              }}
                            />
                            <ExpandLess fontSize="small" />
                          </>
                        ) : (
                          <>
                            <ChatBubbleOutlineOutlined fontSize="small" />
                            <ExpandMore fontSize="small" />
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="flex gap-2 flex-col sm:flex-row">
                        <SimpleButton
                          children="Not Needed"
                          styleString="danger"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeType(type as ServiceTypes);
                          }}
                          icon="Remove"
                        />
                        <SimpleButton
                          children="Create Request"
                          styleString="secondary"
                          icon="Add"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNewRequest(type as ServiceTypes);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {showRequestType &&
                    showRequestType.includes(type as ServiceTypes) && (
                      <div className="flex flex-col gap-2 w-full">
                        {request.items.length === 0
                          ? emptyRequests(type as ServiceTypes)
                          : requests(type as ServiceTypes)}
                      </div>
                    )}
                </div>
              ))}
            </div>
          </CardContainer>

          <FooterContainer>
            <SimpleButton
              onClick={() => handleResidentDeleteRequest()}
              styleString="danger"
              children="Delete Resident"
              icon="DeleteOutlineOutlined"
            />
            <SimpleButton
              onClick={() => toggleActiveStatus(resident)}
              styleString="minimal"
              disabled={resident.overall_status !== "Complete"}
              icon={
                resident.active_discharge
                  ? "Inventory2Outlined"
                  : "RedoOutlined"
              }
              children={
                <>
                  {resident.active_discharge ? (
                    <span>Complete discharge</span>
                  ) : (
                    <span>Move to active</span>
                  )}
                </>
              }
            />
          </FooterContainer>
        </>
      ) : (
        <CardContainer>
          <div className=" flex rounded-md w-full">
            <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
              <div className="flex flex-col space-y-3">
                <div className="w-56 bg-p1 h-12 rounded" />
                <div className="w-full grid-cols-2 py-4 grid mb-4">
                  <div className="grid grid-cols-2 w-96 gap-y-2">
                    {Array(8)
                      .fill(null)
                      .map((_, index) => (
                        <div key={index} className="w-40 bg-p1 h-6 rounded" />
                      ))}
                  </div>
                </div>
                <div className="flex flex-col w-full gap-y-2">
                  {Array(4)
                    .fill(null)
                    .map((_, index) => (
                      <div key={index} className="w-3/4 bg-p1 h-6 rounded" />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </CardContainer>
      )}

      <NewServiceRequestForm
        open={createRequest}
        setOpen={setCreateRequest}
        providerType={providerType}
      />
      <CancelModal
        open={showDeleteRequest}
        setOpen={setShowDeleteRequest}
        title={"Delete Service Request"}
        paragraph={"Are you sure you want to delete this service request?"}
        submitClick={() => deleteRequest(selectedRequest)}
      />
      {resident && (
        <>
          <CancelModal
            open={showResidentDeleteRequest}
            setOpen={setShowResidentDeleteRequest}
            title={"Delete Resident"}
            paragraph={"Are you sure you want to delete this resident?"}
            submitClick={() => deleteResidentAction(resident)}
          />
          <OverlayModal
            open={showNewType}
            setOpen={setShowNewType}
            content={
              <AddServiceTypeForm
                required_types={resident.required_request_types}
                onSubmit={handleNewTypeSubmit}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default ViewResident;
