import moment from "moment";
import "moment-timezone";

export const formatDate = (
  date: Date,
  format: string = "YYYY-MM-DD",
  timezone = `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
): string => {
  if (!date) {
    return "N/a";
  } else if (!moment(date).isValid()) {
    return "Invalid Date";
  } else if (format === "chat") {
    const now = moment().tz(timezone);
    const inputDate = moment(date).tz(timezone);

    if (inputDate.isSame(now, "day")) {
      return `Today ${inputDate.format("hh:mm A")}`;
    } else if (inputDate.isSame(now.clone().subtract(1, "day"), "day")) {
      return `Yesterday ${inputDate.format("hh:mm A")}`;
    } else if (inputDate.isAfter(now.clone().startOf("week"))) {
      return `${inputDate.format("dddd")} ${inputDate.format("hh:mm A")}`;
    } else {
      return inputDate.format("YYYY-MM-DD hh:mm A");
    }
  } else {
    return moment(date).tz(timezone).format(format);
  }
};

export const capitalizeString = (str: string): string => {
  if (!str) return "Invalid String";

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};
