import { SetURLSearchParams } from "react-router-dom";

export const updateSearchParams = (
  setSearchParams: SetURLSearchParams,
  params: object
) => {
  setSearchParams(
    (prevParams) => ({
      ...Object.fromEntries(prevParams as any),
      ...params,
    }),
    { replace: true }
  );
};
