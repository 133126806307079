import React from "react";
import { UseFormRegister } from "react-hook-form";

interface RadioInputProps {
  label: string;
  register: UseFormRegister<any>;
  fieldName: string;
  disabled?: boolean;
  options: { label: string | null; value: string }[];
  type?: string;
  error?: string;
}

const RadioInput: React.FC<RadioInputProps> = ({
  label,
  register,
  fieldName,
  options,
  error,
}) => {
  return (
    <>
      <fieldset>
        <legend className="text-md font-semibold leading-6 text-gray-900">
          {label}
        </legend>
        <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
          <label
            htmlFor={`option-none`}
            className="relative flex items-start py-4 cursor-pointer"
          >
            <div className="min-w-0 flex-1 text-sm leading-6">
              <span className="select-none font-medium text-gray-900">
                None
              </span>
            </div>
            <div className="ml-3 flex h-6 items-center">
              <input
                id={`option-none`}
                type="radio"
                className="form-radio h-4 w-4 border-gray-300 text-p2-100 focus:ring-p2-100"
                {...register(fieldName)}
                value=""
              />
            </div>
          </label>
          {options.map((option, index) => (
            <label
              key={index}
              htmlFor={`option-${option.value}`}
              className="relative flex items-start py-4 cursor-pointer"
            >
              <div className="min-w-0 flex-1 text-sm leading-6">
                <span className="select-none font-medium text-gray-900">
                  {option.label}
                </span>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id={`option-${option.value}`}
                  type="radio"
                  className="form-radio h-4 w-4 border-gray-300 text-p2-100 focus:ring-p2-100"
                  {...register(fieldName)}
                  value={option.value}
                />
              </div>
            </label>
          ))}
        </div>
      </fieldset>
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </>
  );
};

export default RadioInput;
