import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorOutline } from "@mui/icons-material";
import { ServiceRequest } from "../../types/ServiceRequest";
import { formatDate } from "../../utils/Format";
import SimpleInput from "../general-components/Input";
import TextArea from "../general-components/TextArea";
import SimpleButton from "../general-components/Button";

type TerminateProps = {
  selectedRequest: ServiceRequest;
  onSubmit: (data: FormValues) => void;
};
interface FormValues {
  requestId: string;
  service_end_date: Date;
  termination_notes?: string;
}

const TerminateContent: React.FC<TerminateProps> = ({
  selectedRequest,
  onSubmit,
}) => {
  const discharge_date = formatDate(
    selectedRequest.resident.discharge_date,
    "MM/DD/YYYY",
    "utc"
  );

  const schema = yup.object().shape({
    service_end_date: yup
      .date()
      .min(discharge_date, "Date must be after Discharge Date")
      .required("Start Date is required"),
    requestId: yup.string().required("Request ID is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      requestId: selectedRequest._id,
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2">
        <h2 className="text-text mr-10">
          To terminate services, select a termination date and provide details.
        </h2>
        <SimpleInput
          label="Service Stop Date"
          type="date"
          fieldName="service_end_date"
          register={register}
          error={errors.service_end_date?.message}
        />
        <TextArea
          label="Notes"
          fieldName="termination_notes"
          register={register}
          error={errors.termination_notes?.message}
          rows={3}
          placeholder="Please provide notes here such as reason for termination and termination location...."
          resize="vertical"
        />

        {selectedRequest && (
          <SimpleButton
            type="submit"
            children="Terminate"
            styleString="primary"
            overrideStyles="w-full"
            disabled={Object.keys(errors).length > 0}
          />
        )}
      </div>
    </form>
  );
};

export default TerminateContent;
