import React, { useEffect, useState } from "react";
import DischargeTable from "../components/tables/DischargeTable";
import NewResidentForm from "../components/forms/NewResidentForm";
import OverlayModal from "../components/modals/OverlayModal";
import CardContainer from "../components/container/CardContainer";
import PageHeader from "../components/general-components/PageHeader";
import SimpleButton from "../components/general-components/Button";
import MonthView from "../components/calendar/MonthCalendar";
import { useReferral } from "../hooks/ReferralHook";
import CalendarHeader from "../components/calendar/CalendarHeader";
import FilterComponent from "../components/general-components/Filter";

const Referrals: React.FC = () => {
  const {
    residents,
    handleViewChange,
    setShowNewResident,
    appendResidents,
    moreItems,
    handleView,
    users,
    showNewResident,
    onNewResidentSubmit,
    loading,
    filters,
    view,
    handleFilterChange,
    initializeData,
  } = useReferral();

  const statuses = ["ACTIVE"];
  useEffect(() => {
    initializeData(statuses);
  }, []);

  interface DateParams {
    currentDate: string;
    startDate: string;
    endDate: string;
    currentView: "day" | "week" | "month" | "year";
  }

  const [dateParams, setDateParams] = useState<DateParams>({
    currentDate: new Date().toISOString(),
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    currentView: "month",
  });

  const filterOptions = [
    {
      name: "resident",
      label: "Resident",
      options: residents.map((resident) => ({
        value: resident._id,
        label: resident.first_name + " " + resident.last_name,
      })),
      searchable: true,
    },
    {
      name: "assignee",
      label: "Assignee",
      options: users.map((user) => ({
        value: user._id,
        label: user.family_name + " " + user.given_name,
      })),
      searchable: true,
    },
    {
      name: "status",
      label: "Status",
      options: [
        { value: "Not Started", label: "Not Started" },
        { value: "Pending", label: "In Progress" },
        { value: "Complete", label: "Completed" },
      ],
      searchable: false,
    },
    {
      name: "discharge_date",
      label: "Discharge Date",
      options: [
        { value: "today", label: "Today" },
        { value: "this-week", label: "This Week" },
        { value: "this-month", label: "This Month" },
        { value: "this-year", label: "This Year" },
      ],
      searchable: false,
    },
  ];

  return (
    <>
      <CardContainer>
        <PageHeader title="Referrals" />
        <div className="flex w-full justify-between my-2">
          <div className="flex gap-2">
            <FilterComponent
              filterOptions={filterOptions}
              filters={filters}
              handleFilterChange={handleFilterChange}
            />
            <span
              className={`isolate inline-flex rounded-md border shadow-sm divide-x `}
            >
              <SimpleButton
                styleString="minimal"
                icon="ListOutlined"
                onClick={() => handleViewChange("list")}
                children="List"
                overrideStyles={view === "list" ? "bg-ap1-300 text-grayscale-100" : ""}
                />
              <SimpleButton
                styleString="minimal"
                icon="CalendarTodayOutlined"
                onClick={() => handleViewChange("calendar")}
                children="Calendar"
                overrideStyles={view === "calendar" ? "bg-ap1-300 text-grayscale-100" : ""}

              />
            </span>
          </div>
          <>
            <SimpleButton
              styleString="secondary"
              onClick={() => setShowNewResident(true)}
              children="New Referral"
            />
          </>
        </div>

        {view === "list" ? (
          <DischargeTable
            residents={residents}
            handleView={handleView}
            handleLoadMore={() => appendResidents(statuses)}
            isMoreItems={moreItems}
            users={users}
            loading={loading}
          />
        ) : (
          <>
            <CalendarHeader
              dateParams={dateParams}
              setDateParams={setDateParams}
            />
            <MonthView residents={residents} />
          </>
        )}
      </CardContainer>
      <OverlayModal
        open={showNewResident}
        setOpen={setShowNewResident}
        content={<NewResidentForm onSubmit={onNewResidentSubmit} />}
      />
    </>
  );
};

export default Referrals;
