import { ExpandLess } from "@mui/icons-material";
import React from "react";
import { formatDate } from "../utils/Format";
import SimpleButton from "../components/general-components/Button";
import CardContainer from "../components/container/CardContainer";
import FooterContainer from "../components/container/FooterContainer";
import ResidentSkeleton from "../components/datadisplay/ResidentSkeleton";
import OverlayModal from "../components/modals/OverlayModal";
import { useProvider } from "../hooks/ProviderHook";
import AcceptContent from "../components/forms/AcceptResidentForm";
import RejectContent from "../components/forms/RejectResidentForm";
import TerminateContent from "../components/forms/TerminateServicesForm";
import { useParams } from "react-router";
import Spinner from "../components/general-components/Spinner";
import InitialBadge from "../components/general-components/InitialBadge";
import UserSelector from "../components/general-components/UserSelector";

const ProviderResidentView: React.FC = () => {
  const {
    selectedRequest,
    onAcceptSubmit,
    onRejectSubmit,
    onTerminateSubmit,
    modalState,
    handleModalToggle,
    loading,
    loadingExternalResident,
    externalResident,
    fetchDocument,
  } = useProvider();

  let demographics: any[] = [];
  if (externalResident && externalResident.residentDemographics) {
    demographics = [
      {
        label: "Address",
        value: externalResident.residentDemographics?.Resident_Address,
      },
      {
        label: "Address 2",
        value: externalResident.residentDemographics?.Resident_Address2,
      },
      {
        label: "City",
        value: externalResident.residentDemographics?.Resident_City,
      },
      {
        label: "State",
        value: externalResident.residentDemographics?.Resident_State,
      },
      {
        label: "Zip",
        value: externalResident.residentDemographics?.Resident_ZipCode,
      },
      {
        label: "DOB",
        value: formatDate(externalResident.residentDemographics?.Resident_DOB),
      },
    ];
  }
  let insurance: any[] = [];
  if (externalResident && externalResident.insuranceData) {
    insurance = externalResident.insuranceData.map((item: any) => ({
      Description: item.Insurance_Desc,
      "Membership Number": item.ResidentInsurance_MembershipNum,
      "Expiration Date": formatDate(item.ResidentInsurance_ExpirationDate),
    }));
  }

  const pageParam = useParams();
  const { status } = pageParam;

  return (
    <div className="box-border h-full flex flex-col">
      <button
        className="mx-4 my-2 self-start text-gray-500 text-sm flex items-center hover:shadow-sm"
        onClick={() => window.history.back()}
      >
        <ExpandLess fontSize="small" className="-rotate-90" />
        <span>
          Back to{" "}
          {status === "pending"
            ? "Pending Requests"
            : status === "accepted"
            ? "Active Cases"
            : status === "rejected"
            ? "Rejected Cases"
            : "Terminated Cases"}
        </span>
      </button>
      {selectedRequest?.resident && !loading ? (
        <>
          <CardContainer>
            <h1 className="text-2xl text-gray-600 font-semibold border-b border-acc">
              {selectedRequest.resident.first_name}{" "}
              {selectedRequest.resident.last_name}
            </h1>
            <div className="w-full grid-cols-1 lg:grid-cols-2 py-4 grid border-b border-gray-200 mb-4 ">
              <div className="grid grid-cols-2 w-96 gap-y-2">
                <span className="text-gray-800 font-semibold ">
                  Date Created:{" "}
                </span>
                <span className="text-md text-gray-500">
                  {formatDate(
                    selectedRequest.resident.date_created,
                    "MM/DD/YYYY",
                    "utc"
                  )}
                </span>
                <span className="text-gray-800 font-semibold">
                  Discharge Date:{" "}
                </span>
                <span className="text-md text-gray-500">
                  {formatDate(
                    selectedRequest.resident.discharge_date,
                    "MM/DD/YYYY",
                    "utc"
                  )}
                </span>
                <span className="text-gray-800 font-semibold">Files: </span>
                <div className="flex flex-col">
                  <button
                    onClick={() =>
                      fetchDocument(selectedRequest.resident._id, "face_sheet")
                    }
                    className="w-fit text-md text-p2-100 underline"
                  >
                    Face Sheet
                  </button>
                </div>
                <span className="text-gray-800 font-semibold ">
                  Contact Person:{" "}
                </span>
                {!selectedRequest.resident.discharge_coordinators ||
                selectedRequest.resident.discharge_coordinators.length === 0 ? (
                  <InitialBadge
                    given_name={"Unassigned"}
                    family_name={" "}
                    _id={"0"}
                    index={-1}
                  />
                ) : (
                  selectedRequest.resident.discharge_coordinators?.map(
                    (coordinator, index) => (
                      <InitialBadge
                        given_name={coordinator?.user.given_name}
                        family_name={coordinator?.user.family_name}
                        _id={coordinator?.user._id}
                        index={index}
                      />
                    )
                  )
                )}
                <span className="text-gray-800 font-semibold ">
                  Case Manager:{" "}
                </span>
                <UserSelector assignedToId={selectedRequest?.assigned_to?._id ?? "0"} />
  
              </div>
            </div>

            {loadingExternalResident ? (
              <div className="flex flex-col gap-4 h-96 w-full rounded justify-center items-center bg-gray-50">
                <Spinner />
                <span className="text-sm">Waiting for response from API</span>
              </div>
            ) : (
              <>
                {externalResident.residentDemographics && (
                  <div className="grid grid-cols-2 gap-2 mb-4 w-3/4">
                    {demographics.map((item, index) => (
                      <React.Fragment key={index}>
                        <span className="text-gray-800">{item.label}: </span>
                        <span className="text-md text-gray-700">
                          {item.value}
                        </span>
                      </React.Fragment>
                    ))}
                  </div>
                )}
                {insurance.length > 0 && (
                  <div className="gap-2 mb-4 w-full overflow-auto scrollbar-thin h-64 relative">
                    <h2 className="text-lg text-gray-800 font-semibold sticky z-10 top-0 bg-white shadow py-2">
                      Insurance Information
                    </h2>
                    <div className="flex flex-col my-2 divide-y border-y border-gray-200">
                      {insurance.map((item, index) => (
                        <div className=" my-2 " key={index}>
                          <span>{index}</span>
                          <div>
                            <span className="text-gray-800 font-medium">
                              Description:{" "}
                            </span>
                            <span className="text-md text-gray-700">
                              {item.Description ?? "N/a"}
                            </span>
                          </div>
                          <div>
                            <span className="text-gray-800 font-medium">
                              Membership Number:{" "}
                            </span>
                            <span className="text-md text-gray-700">
                              {item["Membership Number"] ?? "N/a"}
                            </span>
                          </div>
                          <div>
                            <span className="text-gray-800 font-medium">
                              Expiration Date:{" "}
                            </span>
                            <span className="text-md text-gray-700">
                              {item["Expiration Date"]}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </CardContainer>

          <FooterContainer>
            {status === "pending" ? (
              <>
                <SimpleButton
                  onClick={() => handleModalToggle("showReject", true)}
                  styleString="danger"
                  children="Reject"
                  icon="Close"
                />
                <SimpleButton
                  onClick={() => handleModalToggle("showAccept", true)}
                  styleString="bold"
                  children="Accept"
                  icon="Check"
                />
              </>
            ) : status === "accepted" ? (
              <>
                <SimpleButton
                  onClick={() => handleModalToggle("showTerminate", true)}
                  styleString="danger"
                  children="Terminate Services"
                />
              </>
            ) : null}
          </FooterContainer>

          {selectedRequest && (
            <>
              <OverlayModal
                open={modalState.showAccept}
                setOpen={(isOpen) => handleModalToggle("showAccept", isOpen)}
                content={
                  <AcceptContent
                    selectedRequest={selectedRequest}
                    onSubmit={onAcceptSubmit}
                  />
                }
              />
              <OverlayModal
                open={modalState.showReject}
                setOpen={(isOpen) => handleModalToggle("showReject", isOpen)}
                content={
                  <RejectContent
                    selectedRequest={selectedRequest}
                    onSubmit={onRejectSubmit}
                    onCancel={() => handleModalToggle("showReject", false)}
                  />
                }
              />
              <OverlayModal
                open={modalState.showTerminate}
                setOpen={(isOpen) => handleModalToggle("showTerminate", isOpen)}
                content={
                  <TerminateContent
                    selectedRequest={selectedRequest}
                    onSubmit={onTerminateSubmit}
                  />
                }
              />
            </>
          )}
        </>
      ) : (
        <ResidentSkeleton />
      )}
    </div>
  );
};

export default ProviderResidentView;
