import React from "react";
import { ServiceRequest } from "../../types/ServiceRequest";
import SimpleButton from "../general-components/Button";

type RejectProps = {
  selectedRequest: ServiceRequest;
  onSubmit: (data: string) => void;
  onCancel: () => void;
};

const RejectContent: React.FC<RejectProps> = ({
  selectedRequest,
  onSubmit,
  onCancel,
}) => {
  return (
    <>
      <h2 className="text-text mr-10">
        Are you sure you want to reject this case?
      </h2>
      <p className="pb-6 text-text text-sm">
        This action can not be undone.
      </p>
      <div className="flex gap-2">
        <SimpleButton
          onClick={() => onCancel()}
          styleString="secondary"
          children="Cancel"
          overrideStyles="w-full"
        />
        {selectedRequest && (
          <SimpleButton
            onClick={() => onSubmit(selectedRequest._id)}
            styleString="danger"
            children="Reject"
            overrideStyles="w-full"
          />
        )}
      </div>
    </>
  );
};

export default RejectContent;
