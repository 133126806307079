import { LockClock } from "@mui/icons-material";
import moment from "moment";
import { Resident } from "../../types/Resident";
import { useReferral } from "../../hooks/ReferralHook";
import { useEffect, useState } from "react";

function generateMonth(date: moment.MomentInput) {
  const monthStart = moment(date).startOf("month");
  const monthEnd = moment(date).endOf("month");

  const days = [];

  // Add padding days at the beginning of the month until the first day of the week
  for (
    let date = monthStart.clone().startOf("week");
    date.isBefore(monthStart);
    date.add(1, "day")
  ) {
    days.push({
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: false,
      isToday: false,
      isSelected: false,
      events: [],
    });
  }

  // Add actual days of the month
  for (
    let date = monthStart.clone();
    date.isSameOrBefore(monthEnd);
    date.add(1, "day")
  ) {
    const isToday = date.isSame(moment(), "day");
    const isSelected = date.isSame(moment(date), "day");

    days.push({
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: true,
      isToday: isToday,
      isSelected: isSelected,
      events: [],
    });
  }

  // Add padding days at the end of the month until the end of the week
  for (
    let date = monthEnd.clone().add(1, "day");
    days.length < 42;
    date.add(1, "day")
  ) {
    days.push({
      date: date.format("YYYY-MM-DD"),
      isCurrentMonth: false,
      isToday: false,
      isSelected: false,
      events: [],
    });
  }

  return days;
}

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

interface MonthViewProps {
  residents: Resident[];
}

export default function MonthView({ residents }: MonthViewProps) {
  const { date, handleView } = useReferral();
  const dates = generateMonth(date);
  const [selectedDay, setSelectedDay] = useState<{ date: string } | null>(null);

  const handleDateClick = (date: string) => {
    setSelectedDay({ date });
  };

  const selectedDayTasks = residents.filter((resident) => {
    if (!selectedDay) return false;
    return moment(resident.discharge_date).isSame(
      moment(selectedDay.date),
      "day"
    );
  });

  return (
    <div className="lg:flex lg:h-full  lg:flex-col">
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
          <div className="bg-white py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {dates.map((day) => {
              const daysTask = residents.filter((resident) =>
                moment(resident.discharge_date).isSame(day.date, "day")
              );

              return (
                <div
                  key={day.date}
                  className={classNames(
                    day.isCurrentMonth
                      ? "bg-white"
                      : "bg-gray-50 text-gray-500",
                    "relative px-3 py-2 h-28"
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={
                      day.isToday
                        ? "flex h-6 w-6 items-center justify-center rounded bg-accent-200 font-semibold bg-ap1 text-p1"
                        : undefined
                    }
                  >
                    {day?.date?.split("-").pop()?.replace(/^0/, "") ?? ""}
                  </time>
                  
                  {daysTask.length > 0 && (
                    <ol className="mt-2">
                      {daysTask.slice(0, 2).map((resident) => (
                        <li key={resident._id}>
                          <div
                            className={`group flex cursor-pointer border-l-2 pl-1 ${
                              resident.overall_status === "Complete"
                                ? "border-success"
                                : resident.overall_status === "Pending"
                                ? "border-warning"
                                : resident.overall_status === "In Progress"
                                ? "border-r"
                                : resident.overall_status === "Rejected"
                                ? "border-error"
                                : "border-info"
                            }`}
                            onClick={() =>
                              handleView(resident, window.location.pathname)
                            }
                          >
                            <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-accent-200">
                              {resident.first_name} {resident.last_name}
                            </p>
                          </div>
                        </li>
                      ))}
                      {daysTask.length > 2 && (
                        <li className="text-gray-500">
                          + {daysTask.length - 2} more
                        </li>
                      )}
                    </ol>
                  )}
                </div>
              );
            })}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {dates.map((day) => {
              const daysTask = residents.filter((resident) =>
                moment(resident.discharge_date).isSame(day.date, "day")
              );

              return (
                <button
                  key={day.date}
                  type="button"
                  onClick={() => handleDateClick(day.date)}
                  className={classNames(
                    day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                    (day.isSelected || day.isToday) && "font-semibold",
                    day.isSelected && "text-white",
                    !day.isSelected && day.isToday && "text-accent-200",
                    !day.isSelected &&
                      day.isCurrentMonth &&
                      !day.isToday &&
                      "text-gray-900",
                    !day.isSelected &&
                      !day.isCurrentMonth &&
                      !day.isToday &&
                      "text-gray-500",
                    "flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10"
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      day.isSelected &&
                        "flex h-6 w-6 items-center justify-center rounded-full",
                      day.isSelected && day.isToday && "bg-accent-200",
                      day.isSelected && !day.isToday && "bg-gray-900",
                      "ml-auto"
                    )}
                  >
                    {day.date?.split("-").pop()?.replace(/^0/, "") ?? ""}
                  </time>
                  <span className="sr-only">{day.events.length} events</span>
                  {daysTask.length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {daysTask.map((resident) => (
                        <span
                          key={resident._id}
                          className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                        />
                      ))}
                    </span>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      {selectedDayTasks.length > 0 && (
        <div className="px-4 py-10 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {selectedDayTasks.map((resident) => (
              <li
                key={resident._id}
                className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
              >
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900">
                    {resident.first_name} {resident.last_name}
                  </p>
                  <time
                    dateTime={
                      moment(resident.discharge_date).format("YYYY-MM-DD") ?? ""
                    }
                    className="mt-2 flex items-center text-gray-700"
                  >
                    <LockClock
                      className="mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {moment(resident.discharge_date).format("MMMM D, YYYY")}
                  </time>
                </div>
                <button
                  onClick={() => handleView(resident, window.location.pathname)}
                  className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                >
                  View
                  <span className="sr-only">
                    , {resident.first_name} {resident.last_name}
                  </span>
                </button>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
