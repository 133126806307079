import { SetURLSearchParams } from "react-router-dom";
import { updateSearchParams } from "../utils/ParamUtils";


export const handleView = (
  requestId: string,
  route: string,
  navigate: (to: string) => void
): void => {
  navigate(`${route}/${requestId}`);
};

export const handleFilterChange = (
  newFilters: { [key: string]: any },
  setSearchParams: SetURLSearchParams
) => {
  updateSearchParams(setSearchParams, {
    filters: JSON.stringify(newFilters),
  });
};


