import React, { createContext, useState, useEffect } from "react";
import { Resident } from "../types/Resident";
import { ServiceRequest, ServiceTypes } from "../types/ServiceRequest";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSession } from "../hooks/SessionHook";
import { useSocket } from "./ChatContext";
import apiClient from "../api/apiClient";

// Define the type for the context
type ResidentContextType = {
  resident: Resident | null;
  setResident: (resident: Resident) => void;
  createServiceRequest: (data: RequestProps) => Promise<void>;
  deleteServiceRequest: (request: ServiceRequest | null) => Promise<void>;
  toggleActiveStatus: (resident: Resident) => Promise<void>;
  deleteResident: (resident: Resident) => Promise<void>;
  removeType: (type: ServiceTypes) => Promise<void>;
  addType: (data: { newTypes: ServiceTypes[] }) => Promise<void>;
  loading: boolean;
  fetchDocument: (residentId: string, fileType: string) => Promise<void>;
};

interface RequestProps {
  nursing_organization: string;
  provider_organization: string;
  service_type: string;
  resident_id: string;
}

// Create the context
export const ResidentContext = createContext<ResidentContextType>(
  {} as ResidentContextType
);

interface ResidentContextProviderProps {
  children: React.ReactNode;
}
// Create the ResidentContext component
const ResidentContextProvider: React.FC<ResidentContextProviderProps> = ({
  children,
}) => {
  const token = sessionStorage.getItem("token");
  const { user } = useSession();
  const { socket } = useSocket();
  // Get the resident id from the url params
  const { id } = useParams();

  const [resident, setResident] = useState<Resident | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchResidentData = async () => {
      if (id) {
        await fetchResident(id);
      }
    };

    fetchResidentData();
  }, [id]);

  const fetchResident = async (id: string): Promise<void> => {
    try {
      const response = await apiClient.get(`/referrals/resident/${id}`);
      setResident(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching resident", error);
    }
  };

  // Function to create a service request
  const createServiceRequest = async (data: RequestProps): Promise<void> => {
    try {
      const {
        resident_id,
        nursing_organization,
        provider_organization,
        service_type,
      } = data;

      // if no socket throw an error
      if (!socket || !user) {
        throw new Error("Socket connection not found or missing user data");
      } else {
        // Emit the "create_request" event
        socket.emit("create_request", {
          resident: resident_id,
          nursing_organization: nursing_organization,
          provider_organization: provider_organization,
          service_type: service_type,
        });

        // Listen for the "request_created" event and spread in the new request
        socket.on("request_created", (request: ServiceRequest) => {
          if (resident) {
            const updatedResident = {
              ...resident,
              service_requests: [...(resident.service_requests || []), request],
            };
            console.log("updatedResident", updatedResident);
            setResident(updatedResident);
          }
          socket.emit("request_user_chats", user._id, "");
        });
      }
    } catch (error) {
      console.error("Error creating service request", error);
    }
  };

  // Function to delete the service request
  const deleteServiceRequest = async (
    request: ServiceRequest | null
  ): Promise<void> => {
    try {
      if (!request || !socket) return;

      socket.emit("delete_request", {
        request_id: request._id,
      });

      socket.on("request_deleted", (requestId: string) => {
        if (resident) {
          const updatedResident = {
            ...resident,
            service_requests: [
              ...(resident.service_requests || []).filter(
                (r) => r._id !== requestId
              ),
            ],
          };
          setResident(updatedResident);
        }
      });
    } catch (error) {
      console.error("Error deleting request", error);
    }
  };
  // Function to toggle the active status
  const toggleActiveStatus = async (resident: Resident): Promise<void> => {
    try {
      await axios
        .put(
          `/api/referrals/resident/status/${resident._id}`,
          {
            active_discharge: !resident.active_discharge,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-User-Id": user?._id ?? "",
            },
          }
        )
        .then(async () => {
          if (id) {
            await fetchResident(id);
          }
        });
    } catch (error) {
      console.error("Error updating resident", error);
    }
  };

  const removeType = async (type: ServiceTypes) => {
    try {
      if (!resident) return;
      await axios.delete(
        `/api/referrals/service-request-type?serviceType=${type}&residentId=${resident._id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      setResident((prevResident) => {
        if (prevResident) {
          return {
            ...prevResident,
            required_request_types: [
              ...(prevResident.required_request_types || []).filter(
                (t) => t !== type
              ),
            ],
          };
        }
        return prevResident;
      });
    } catch (error) {
      console.error("Error removing service type:", error);
    }
  };

  const addType = async (data: { newTypes: ServiceTypes[] }) => {
    try {
      if (!resident) return;
      await axios.post(
        `/api/referrals/service-request-type`,
        {
          serviceTypes: data.newTypes,
          residentId: resident._id,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      setResident((prevResident) => {
        if (prevResident) {
          return {
            ...prevResident,
            required_request_types: [
              ...(prevResident.required_request_types || []),
              ...data.newTypes,
            ].sort(),
          };
        }
        return prevResident;
      });
    } catch (error) {
      console.error("Error adding service type:", error);
    }
  };

  // Function to delete the resident
  const deleteResident = async (resident: Resident): Promise<void> => {
    try {
      await axios.delete(`/api/referrals/resident/${resident._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": user?._id ?? "",
        },
      });
    } catch (error) {
      console.error("Error deleting resident", error);
    }
  };

  const fetchDocument = async (residentId: string, fileType: string) => {
    try {
      const response = await axios.get(
        `/api/referrals/document?residentId=${residentId}&fileType=${fileType}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      console.log("response", response);
      const url = window.URL.createObjectURL(response.data);
      window.open(url);
    } catch (error) {
      console.error("Error fetching the document:", error);
    }
  };

  // Provide the context value to the children components
  return (
    <ResidentContext.Provider
      value={{
        resident,
        setResident,
        createServiceRequest,
        deleteServiceRequest,
        toggleActiveStatus,
        deleteResident,
        removeType,
        addType,
        loading,
        fetchDocument,
      }}
    >
      {children}
    </ResidentContext.Provider>
  );
};

export default ResidentContextProvider;
