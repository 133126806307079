import React from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { Resident } from "../../types/Resident";
import { formatDate } from "../../utils/Format";
import { useSession } from "../../hooks/SessionHook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SimpleInput from "../general-components/Input";
import SimpleButton from "../general-components/Button";
import { StylesConfig, CSSObjectWithLabel } from "react-select";
import { menuListCSS } from "react-select/dist/declarations/src/components/Menu";

interface FormValues {
  discharge_date: Date;
  resident_id: string;
  first_name: string;
  last_name: string;
  dob: string;
}

interface NewResidentFormProps {
  onSubmit: (data: FormValues) => void;
}

const NewResidentForm: React.FC<NewResidentFormProps> = ({ onSubmit }) => {
  const { currentOrganization, user } = useSession();

  const loadOptions = async (inputValue: string): Promise<Resident[]> => {
    if (!currentOrganization) return Promise.resolve([]);

    const url = `/api/referrals/external/residents?name=${inputValue}&facilityId=${currentOrganization._id}`;
    const token = sessionStorage.getItem("token");

    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      return res.data.map((resident: any) => ({
        value: resident._id,
        label: `${resident.first_name} ${resident.last_name} - ${formatDate(
          resident.dob,
          "MM/DD/YYYY"
        )}`,
        first_name: resident.first_name,
        last_name: resident.last_name,
        dob: resident.dob,
      }));
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const newResidentSchema = yup.object().shape({
    first_name: yup.string().required("*First name is required"),
    last_name: yup.string().required("*Last name is required"),
    dob: yup.string().required("*Date of birth is required"),
    discharge_date: yup
      .date()
      .min(new Date(), "Must be a future date.")
      .required("*Discharge date is required"),
    resident_id: yup.string().required("*A resident must be selected"),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(newResidentSchema),
  });

  const residentId = watch("resident_id");
  const discharge_date = watch("discharge_date");

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-2">
      <div>
        <label
          htmlFor="selected_resident"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Search by name
        </label>
        <AsyncSelect
          className="font-lato"
          loadOptions={loadOptions}
          isSearchable
          name="selected_resident"
          id="selected_resident"
          onChange={(selectedOption: any) => {
            setValue("resident_id", selectedOption.value);
            setValue("first_name", selectedOption.first_name);
            setValue("last_name", selectedOption.last_name);
            setValue("dob", selectedOption.dob);
          }}
          styles={customStyles}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
        {errors?.resident_id && (
          <p className="text-xs text-warning-500">
            {errors?.resident_id?.message}
          </p>
        )}
      </div>

      <SimpleInput
        type="date"
        label="Discharge Date"
        register={register}
        fieldName="discharge_date"
        error={errors?.discharge_date?.message}
      />

      <SimpleButton
        type="submit"
        children="Create"
        styleString="primary"
        overrideStyles="w-full"
        disabled={
          Object.keys(errors).length > 0 || !residentId || !discharge_date
        }
      />
    </form>
  );
};

// Define custom styles for the react-select component
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? "#E9EBF5" : "#E5E7EB", // Normal state and focused state
    boxShadow: state.isFocused ? "0 0 0 1px #E9EBF5" : "none", // Focused border color
    "&:hover": {
      borderColor: state.isFocused ? "#E9EBF5" : "#E5E7EB", // Hover state
    },
    cursor: "pointer",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#E9EBF5" : "white", // Background color on hover
    color: "black", // Set text color for options
    "&:hover": {
      backgroundColor: "#E9EBF5", // Color on hover
    },
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

export default NewResidentForm;
