// Unauthorized.tsx
import { LockOutlined } from "@mui/icons-material";
import React from "react";
import SimpleButton from "../components/general-components/Button";

const Unauthorized: React.FC = () => {
  return (
    <div className="h-screen bg-p3-100 flex justify-center items-center">
      <div className="bg-p1 rounded flex flex-col justify-center items-center p-4 text-p3-100">
        <LockOutlined className="text-s1-100" style={{ fontSize: "2.2rem" }} />
        <h1 className="font-bold">Unauthorized</h1>
        <p className="italic text-sm my-2">
          You are not authorized to view this content.
        </p>
        <SimpleButton
          onClick={() => window.history.back()}
          children="Go Back"
          styleString="primary"
        />
        {/* <button className="bg-s1-100 text-p1 px-2 py-1 rounded hover:bg-s1-200" onClick={() => window.history.back()}>Go Back</button> */}
      </div>
    </div>
  );
};

export default Unauthorized;
