import React, { useState } from "react";
import { Send } from "@mui/icons-material";
import { useSession } from "../../hooks/SessionHook";
import { useSocket } from "../../context/ChatContext";
import { ChatRoom } from "../../types/ChatRoom";
interface InputSectionProps {
  chatRoom: ChatRoom;
}

const InputSection: React.FC<InputSectionProps> = ({ chatRoom }) => {
  const { user } = useSession();
  const token = sessionStorage.getItem("token");
  const [messageContent, setMessageContent] = useState("");

  // Initialize socket connection
  const { socket } = useSocket();

  const sendMessage = async () => {
    if (!user) return;
    if (!token) return;
    if (!socket) return;

    try {
      let messageData = {
        requestId: chatRoom.service_request,
        authorId: user._id,
        content: messageContent,
      };

      socket.emit("chat_message", messageData);

      setMessageContent("");
    } catch (e) {
      console.log(e);
    }
  };

  const rowsNum = messageContent.split("\n").length;

  return (
    <>
      <textarea
        rows={Math.min(rowsNum, 3)}
        className={`${
          rowsNum === 1 ? "rounded" : "rounded-lg"
        } form-textarea block w-full resize-none scrollbar-none border-0 p-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6`}
        value={messageContent}
        placeholder="Type a message..."
        onChange={(e) => setMessageContent(e.target.value)}
        onKeyDown={(e) => {
          if (
            e.key === "Enter" &&
            !e.shiftKey &&
            messageContent.trim() !== ""
          ) {
            e.preventDefault();
            sendMessage();
          }
        }}
      />
      <button
        onClick={() => sendMessage()}
        className="ml-2 rounded bg-ap1-400 text-text-100 border border-transparent shadow-sm hover:bg-ap1-500 w-10 h-10 flex justify-center items-center"
      >
        <Send style={{ fontSize: "1.2rem" }} />
      </button>
    </>
  );
};

export default InputSection;
