import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import InitialBadge from "./InitialBadge";

interface UserProps {
  _id: string;
  given_name: string;
  family_name: string;
}
const UserSelector = ({ assignedToId }: { assignedToId: string }) => {
  const [selectedUser, setSelectedUser] = useState<UserProps | null>(null);

  const userOptions = [
    { _id: "1", given_name: "John", family_name: "Doe" },
    { _id: "2", given_name: "Jane", family_name: "Doe" },
    { _id: "3", given_name: "John", family_name: "Smith" },
  ];

  const formatOptionLabel = ({
    _id,
    given_name,
    family_name,
  }: {
    _id: string;
    given_name: string;
    family_name: string;
  }) => (
    <div className="flex items-center">
      <InitialBadge
        given_name={given_name}
        family_name={family_name}
        _id={_id}
      />
    </div>
  );

  const handleChange = (selected: SingleValue<UserProps>) => {
    if (selected === null && selectedUser !== null) {
      // Show confirmation dialog before clearing
      const confirmClear = window.confirm(
        "Are you sure you want to clear the selection?"
      );
      if (!confirmClear) {
        return;
      }
    }
    setSelectedUser(selected as UserProps | null);
    console.log(selected);
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex items-center w-full"
    >
      <Select
        options={userOptions}
        isSearchable={false}
        isClearable={true}
        formatOptionLabel={formatOptionLabel}
        value={assignedToId ? userOptions.find((u) => u._id === assignedToId) : null}
        onChange={handleChange}
        menuPortalTarget={document.body}
        styles={{
          container: (provided) => ({
            ...provided,
            minWidth: "100px",
            width: "100%",
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: "0",
          }),
          control: (provided) => ({
            ...provided,
            border: "none",
            boxShadow: "none",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            padding: 0,
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
        }}
      />
    </div>
  );
};

export default UserSelector;
