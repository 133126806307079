import React, { createContext, useState, useEffect, ReactNode } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { Socket } from "socket.io-client";
import { User } from "../types/User";
import { Organization } from "../types/Organization";
import { useSocket } from "./ChatContext";

interface SessionContextProps {
  user: User | null;
  organizations: Organization[];
  currentOrganization: Organization | null;
  changeOrganization: (organization: Organization) => void;
  loginWithCode: (code: string) => void;
  refreshToken: () => Promise<boolean>;
  logout: () => Promise<void>;
  token: string | null;
}

export const SessionContext = createContext<SessionContextProps | undefined>(
  undefined
);

interface SessionProviderProps {
  children: ReactNode;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}) => {
  // const { socket, createSocketConnection, closeSocketConnection } = useSocket();

  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [currentOrganization, setCurrentOrganization] =
    useState<Organization | null>(null);
  const navigate = useNavigate();



  useEffect(() => {
    // Load user information from session storage
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      const parsedUser: User = JSON.parse(storedUser);
      setUser(parsedUser);
      setOrganizations(parsedUser.organizations);

    }

    const storedOrganization = sessionStorage.getItem("organization");
    if (storedOrganization) {
      const parsedOrganization: Organization = JSON.parse(storedOrganization);
      setCurrentOrganization(parsedOrganization);
    }
  }, []);

  const loginWithCode = async (code: string) => {
    try {
      const { data } = await axios.post("/api/auth/callback", { code });

      const { user, localUser, id_token, access_token, refresh_token } = data;

      let redirectUrl = "/pending_account"; // Default redirect if no organization is found

      if (localUser) {
        sessionStorage.setItem("user", JSON.stringify(localUser));
        setUser(localUser);

        const organization = localUser.organizations?.[0];
        if (organization) {
          sessionStorage.setItem("organization", JSON.stringify(organization));
          setOrganizations(localUser.organizations);
          setCurrentOrganization(organization);

          // Determine the redirect URL based on organization type
          if (organization.type === "NURSING") {
            redirectUrl = "/referrals";
          } else if (
            ["CHHA", "LHCSA", "DME", "RPM", "PHARMACY"].includes(
              organization.type
            )
          ) {
            redirectUrl = "/requests/pending";
          } else {
            redirectUrl = "/pending_account";
          }
        } else if (localUser.user_type === "ADMIN") {
          redirectUrl = "/users";
        }
      }

      // Save token to session storage
      sessionStorage.setItem("token", access_token);
      sessionStorage.setItem("refresh_token", refresh_token);

      setToken(access_token);

      // Initialize socket connection
      // createSocketConnection(access_token, localUser._id);

      navigate(redirectUrl); // Always navigate at the end of the function
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const refreshToken = async () => {
    try {
      const refreshToken = sessionStorage.getItem("refresh_token");
      const { data } = await axios.post("/api/auth/refresh", { refreshToken });
      const { access_token } = data;
      if (access_token) {
        sessionStorage.setItem("token", access_token);
        setToken(access_token);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      return false;
    }
  };

  const logout = async () => {
    try {
      // Call the /logout route
      const refreshToken = sessionStorage.getItem("refresh_token");
      await axios.post("/api/auth/logout", { refreshToken }); // Replace with actual username

      // Remove user information from session storage
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("organization");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("refresh_token");
      setUser(null);
      // closeSocketConnection();

      // Redirect to the login page
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const changeOrganization = (organization: Organization) => {
    // Set organization information in session storage
    console.log("organization", organization);

    sessionStorage.setItem("organization", JSON.stringify(organization));
    setCurrentOrganization(organization);
  };


  return (
    <SessionContext.Provider
      value={{
        user,
        organizations,
        currentOrganization,
        changeOrganization,
        loginWithCode,
        refreshToken,
        logout,
        token,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
