import { ServiceRequest } from "../../types/ServiceRequest";
import apiClient from "../apiClient";

export const fetchRequest = async (
  id: string
): Promise<ServiceRequest | undefined> => {
  try {
    const response = await apiClient.get(
      `/services/request?requestId=${id}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching service request", error);
    return undefined;
  }
};

export const fetchRequests = async (
  orgId: string,
  status: string,
  params: any
): Promise<{ hasMore: boolean; serviceRequests: ServiceRequest[] }> => {
  try {
    const response = await apiClient.get(
      `/services/list?provider_id=${orgId}&status=${status}`,
      {
        params: {
          ...params,
        },
      }
    );
    return {
      hasMore: response.data.hasMore,
      serviceRequests: response.data.serviceRequests,
    };
  } catch (error) {
    console.error("Error fetching service requests", error);
    return { hasMore: false, serviceRequests: [] };
  }
};
