import { useContext } from "react";
import { ResidentContext } from "../context/ResidentContext";

export const useResident = () => {
  const context = useContext(ResidentContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
