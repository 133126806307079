import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Select, { SingleValue } from "react-select";
import { Organization } from "../../types/Organization";
import { useSession } from "../../hooks/SessionHook";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ServiceRequest } from "../../types/ServiceRequest";
import OverlayModal from "../modals/OverlayModal";
import { Resident } from "../../types/Resident";
import { ResidentContext } from "../../context/ResidentContext";
import SimpleSelector from "../general-components/Selector";
import RadioInput from "../general-components/RadioInput";
import SimpleButton from "../general-components/Button";

interface Option {
  value: string;
  label: string;
  type: string;
}

interface NewServiceRequestFormProps {
  providerType: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface RequestProps {
  nursing_organization: string;
  provider_organization: string;
  service_type: string;
  resident_id: string;
}

const NewServiceRequestForm: React.FC<NewServiceRequestFormProps> = ({
  providerType,
  open,
  setOpen,
}) => {
  const { currentOrganization, user } = useSession();
  const { resident, createServiceRequest } = useContext(ResidentContext);

  const token = sessionStorage.getItem("token");

  const [options, setOptions] = useState<Option[]>([]);

  const loadOptions = async (facilityId: string): Promise<Option[]> => {
    let options: Option[] = [];
    if (!facilityId) return options;
    try {
      const orgType = providerType;

      const response = await axios.get<Organization[]>(
        `/api/referrals/organizations?facilityId=${facilityId}&orgType=${orgType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": user?._id ?? "",
          },
        }
      );
      const organizations = response.data;

      // Convert organizations to options
      options = organizations.map((org) => ({
        value: org._id,
        label: org.name,
        type: org.type,
      }));
    } catch (error) {
      console.error(error);
    }

    return options;
  };

  useEffect(() => {
    const fetchOptions = async (facilityId: string) => {
      const options = await loadOptions(facilityId);
      setOptions(options);
    };
    if (currentOrganization && currentOrganization._id) {
      fetchOptions(currentOrganization._id);
      setValue("nursing_organization", currentOrganization?._id);
    }
  }, [currentOrganization, providerType]);

  const newServiceRequestSchema = yup.object().shape({
    nursing_organization: yup
      .string()
      .required("*Nursing organization is required"),
    provider_organization: yup
      .string()
      .required("*Provider organization is required"),
    service_type: yup.string().required("*Service type is required"),
    resident_id: yup.string().required("*Resident ID is required"),
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors: requestErrors },
    watch
  } = useForm({
    resolver: yupResolver(newServiceRequestSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!resident) return;

    setValue("service_type", providerType);
    setValue("resident_id", resident._id);
    setValue("provider_organization", "");
    {
      currentOrganization &&
        setValue("nursing_organization", currentOrganization?._id);
    }
  }, [providerType, resident, currentOrganization]);

  const onSubmit = async (data: RequestProps) => {
    createServiceRequest(data);
    setOpen(false);
  };

  const provider_organization = watch("provider_organization");

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="isolate  rounded-md shadow-sm">
        {options.length > 0 ? (
          <RadioInput
            label="Select an organization"
            register={register}
            fieldName="provider_organization"
            options={options}
            error={requestErrors?.provider_organization?.message}
          />
        ) : (
          <select
            className="mt-4 w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-acc-100 sm:text-sm sm:leading-6"
            disabled
          >
            <option value="">No organizations available to select</option>
          </select>
        )}

        <SimpleButton
          type="submit"
          children="Create Request"
          styleString="primary"
          overrideStyles="w-full"
          disabled={Object.keys(requestErrors).length > 0 || !provider_organization}
        />
      </div>
    </form>
  );

  return <OverlayModal open={open} setOpen={setOpen} content={form} />;
};

export default NewServiceRequestForm;
