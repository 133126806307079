import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ServiceTypes } from "../../types/ServiceRequest";
import SimpleButton from "../general-components/Button";
import SimpleInput from "../general-components/Input";
import CheckBox from "../general-components/CheckBox";

type ServiceTypeProps = {
  required_types: ServiceTypes[];
  onSubmit: (data: FormValues) => void;
};

interface FormValues {
  newTypes: ServiceTypes[];
}

const AddServiceTypeForm: React.FC<ServiceTypeProps> = ({
  required_types,
  onSubmit,
}) => {
  console.log(required_types);
  const predefinedTypes: ServiceTypes[] = [
    "CHHA",
    "LHCSA",
    "DME",
    "RPM",
    "PHARMACY",
  ];

  const missingTypes = predefinedTypes.filter(
    (type) => !required_types.includes(type)
  );

  const schema = yup.object().shape({
    newTypes: yup
      .array()
      .of(yup.string().oneOf(predefinedTypes).required())
      .required(),
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { newTypes: [] },
    resolver: yupResolver(schema),
  });

  const newTypes = watch("newTypes");

  const handleCheckboxChange = (type: ServiceTypes) => {
    const updatedTypes = newTypes.includes(type)
      ? newTypes.filter((t) => t !== type)
      : [...newTypes, type];

    setValue("newTypes", updatedTypes);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {missingTypes.length > 0 ? (
        <>
          <h2 className="pb-2 text-gray-700 text-sm mr-10">
            Select the service types you would like to add:
          </h2>

          <CheckBox
            options={missingTypes}
            type="checkbox"
            label="Service Types"
            onChange={(e) =>
              handleCheckboxChange(e.target.value as ServiceTypes)
            }
          />
          {errors.newTypes && (
            <p className="text-red-500 text-xs mt-1">
              {errors.newTypes.message}
            </p>
          )}

          <SimpleButton
            type="submit"
            styleString="primary"
            children="Add Service Types"
            overrideStyles="w-full"
          />
        </>
      ) : (
        <p className="text-text text-lg font-medium mx-auto my-4 flex w-fit">
          All service types are already added.
        </p>
      )}
    </form>
  );
};

export default AddServiceTypeForm;
