import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Resident } from "../types/Resident";
import { useSession } from "../hooks/SessionHook";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { User } from "../types/User";
import moment from "moment";
import {
  createResident,
  fetchResidents,
  fetchUsers,
} from "../api/services/residentService";
import {
  changeDate,
  handleFilterChange,
  handleView,
  handleViewChange,
} from "./ReferralHelper";
import { SubmitHandler } from "react-hook-form";

interface FormValues {
  discharge_date: Date;
  resident_id: string;
  first_name: string;
  last_name: string;
  dob: string;
}

type ViewType = "list" | "calendar";

type RefferalsContextType = {
  residents: Resident[];
  users: User[];
  showNewResident: boolean;
  setShowNewResident: React.Dispatch<React.SetStateAction<boolean>>;
  appendResidents: (statuses: string[]) => void;
  handleViewChange: (newView: ViewType) => void;
  onNewResidentSubmit: SubmitHandler<FormValues>;
  loading: boolean;
  moreItems: boolean;
  handleFilterChange: (filters: { [key: string]: any }) => void;
  changeDate: (
    date: string,
    direction: "forward" | "backward" | "reset"
  ) => void;
  handleView: (resident: Resident, route: string) => void;
  view: ViewType;
  page: string;
  startDate: string;
  endDate: string;
  date: string;
  filters: { [key: string]: any };
  initializeData: (status: string[]) => void;
};

export const ReferralContext = createContext<RefferalsContextType>(
  {} as RefferalsContextType
);

interface ResidentContextProviderProps {
  children: React.ReactNode;
}

const ReferralContextProvider: React.FC<ResidentContextProviderProps> = ({
  children,
}) => {
  const { currentOrganization, user } = useSession();
  const navigate = useNavigate();

  const [showNewResident, setShowNewResident] = useState<boolean>(false);
  const [moreItems, setMoreItems] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [residents, setResidents] = useState<Resident[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const memoizedSearchParams = useMemo(
    () => ({
      view: (searchParams.get("view") as ViewType) || "list",
      startDate: searchParams.get("start") || "",
      endDate: searchParams.get("end") || "",
      date: searchParams.get("date") || moment().format("YYYY-MM-DD"),
      filters: JSON.parse(searchParams.get("filters") || "{}"),
    }),
    [searchParams]
  );

  const fetchResidentsData = useCallback(
    async (status: string[], reset: boolean = false, page:number) => {
      if (!currentOrganization || !user || !user._id) return;
      const params = {
        view: memoizedSearchParams.view,
        startDate: memoizedSearchParams.startDate,
        endDate: memoizedSearchParams.endDate,
        date: memoizedSearchParams.date,
        filters: memoizedSearchParams.filters,
        page: page.toString(),
      };

      setLoading(true);
      const { residents: newResidents, hasMore } = await fetchResidents(
        currentOrganization._id,
        status,
        params
      );

      if (reset) {
        setResidents(newResidents); // Replace residents when initializing or reloading
      } else {
        setResidents((prev) => [...prev, ...newResidents]); // Append residents for pagination
      }

      setMoreItems(hasMore);
      setLoading(false);
    },
    [currentOrganization, user, memoizedSearchParams, page]
  );

  const initializeData = useCallback(
    async (status: string[]) => {
      setPage(1); 
      setResidents([]); // Clear current residents
      await fetchResidentsData(status, true, 1); // Fetch residents with reset
    },
    [fetchResidentsData]
  );

  const appendResidents = async (status: string[]) => {
    if (!loading && moreItems) {
      setPage((prev) => prev + 1);
      if (page > 1) {
        await fetchResidentsData(status, false, page);
      }
    }
  };

  const loadUsers = async (organizationId: string) => {
    const fetchedUsers = await fetchUsers(organizationId);
    setUsers(fetchedUsers);
  };

  useEffect(() => {
    if (!currentOrganization || !user) return;
    loadUsers(currentOrganization._id);
  }, [currentOrganization, user]);


  const onNewResidentSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      if (!currentOrganization || !user) return;
      const resident = await createResident(
        currentOrganization._id,
        user._id,
        data
      );
      const newResidentArray = [...residents, resident].sort(
        (b, a) =>
          new Date(b.discharge_date).getTime() -
          new Date(a.discharge_date).getTime()
      );
      setResidents(newResidentArray);
      setShowNewResident(false);
    } catch (error) {
      console.error("Error creating resident", error);
    }
  };

  return (
    <ReferralContext.Provider
      value={{
        residents,
        users,
        showNewResident,
        setShowNewResident,
        appendResidents,
        handleViewChange: (newView) =>
          handleViewChange(newView, setSearchParams),
        onNewResidentSubmit,
        loading,
        moreItems,
        handleFilterChange: (filters) =>
          handleFilterChange(filters, setSearchParams),
        changeDate: (date, direction) =>
          changeDate(date, direction, setSearchParams),
        handleView: (resident, route) => handleView(resident, route, navigate),
        ...memoizedSearchParams,
        page: page.toString(),
        initializeData,
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
};

export default ReferralContextProvider;
