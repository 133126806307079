import React from "react";
import { classNames } from "../../utils/Format";

interface Tab {
  name: string;
  value: string;
  current: boolean;
  // icon: React.ComponentType<{ className: string }>;
}

interface TabsProps {
  tabs: Tab[];
  onChange: (tabname: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, onChange }) => {
  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>

        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-trt focus:ring-s1"
          defaultValue={tabs.find((tab) => tab.current)?.value}
          onChange={(e) => onChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.value}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={classNames(
                tab.current
                  ? "border-p3-100 text-p3-100"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "group inline-flex items-center border-b-2 py-2 px-1 text-sm font-medium"
              )}
              onClick={() => onChange(tab.value)}
            >
              {/* <tab.icon
                  className={classNames(
                    tab.current
                      ? "text-s1"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                /> */}
              <span>{tab.name}</span>
            </button>
          ))}
        </nav>
      </div>
    </>
  );
};

export default Tabs;
