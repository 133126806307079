import React from "react";

interface CheckBoxProps {
  options: string[];
  label: string;
  disabled?: boolean;
  type?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value?: string;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  options,
  error,
  onChange,
  label,
}) => {
  return (
    <>
      <fieldset>
        <legend className="text-base font-semibold leading-6 text-gray-900">
          {label}
        </legend>
        <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
          {options.map((option) => (
            <label
              key={option}
              htmlFor={option}
              className="relative flex items-start py-4 cursor-pointer"
            >
              <div className="min-w-0 flex-1 text-sm leading-6">
                <span className="select-none font-medium text-gray-900">
                  {option}
                </span>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id={option}
                  name={option}
                  value={option}
                  type="checkbox"
                  className="form-checkbox h-4 w-4 rounded-full border-gray-300 text-s2-100 focus:ring-s2-100"
                  onChange={onChange}
                />
              </div>
            </label>
          ))}
          {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
        </div>
      </fieldset>
    </>
  );
};

export default CheckBox;
