import React from "react";
import CardContainer from "../container/CardContainer";

const ResidentSkeleton: React.FC = () => {
  return (
    <CardContainer>
      <div className="flex rounded-md w-full">
        <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
          <div className="flex flex-col space-y-3">
            <div className="w-56 bg-p1 h-12 rounded" />
            <div className="w-full grid-cols-2 py-4 grid mb-4">
              <div className="grid grid-cols-2 w-96 gap-y-2">
                {Array(8)
                  .fill(null)
                  .map((_, index) => (
                    <div key={index} className="w-40 bg-p1 h-6 rounded" />
                  ))}
              </div>
            </div>
            <div className="flex flex-col w-full gap-y-2">
              {Array(4)
                .fill(null)
                .map((_, index) => (
                  <div key={index} className="w-3/4 bg-p1 h-6 rounded" />
                ))}
            </div>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};

export default ResidentSkeleton;
