import React, { useEffect, useState } from "react";
import SlideOver from "../components/modals/SlideOver";
import Tabs from "../components/general-components/Tabs";
import { Add, Person } from "@mui/icons-material";
import OrganizationTable from "../components/tables/OrganizationTable";
import { Organization } from "../types/Organization";
import axios from "axios";
import OverlayModal from "../components/modals/OverlayModal";
import OrganizationForm from "../components/forms/OrganizationForm";
import { useSession } from "../hooks/SessionHook";
import CardContainer from "../components/container/CardContainer";
import SimpleButton from "../components/general-components/Button";

interface Tab {
  name: string;
  value: string;
  current: boolean;
  icon: React.ComponentType<{ className: string }>;
}

const Organizations: React.FC = () => {
  const { user } = useSession();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization | undefined
  >(undefined);

  const [show, setShow] = useState(false);

  useEffect(() => {
    fetchProviders("NURSING");
  }, []);

  const fetchProviders = async (type: string) => {
    try {
      const response = await axios.get(`/api/organization/list/${type}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      setOrganizations(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [tabs, setTabs] = useState<Tab[]>([
    { name: "Facilities", value: "NURSING", icon: Person, current: true },
    { name: "Pharmacy", value: "PHARMACY", icon: Person, current: false },
    { name: "CHHA", value: "CHHA", icon: Person, current: false },
    { name: "LHCSA", value: "LHCSA", icon: Person, current: false },
    { name: "DME", value: "DME", icon: Person, current: false },
    { name: "RPM", value: "RPM", icon: Person, current: false },
  ]);

  const handleTabChange = (selectedTab: string) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.value === selectedTab,
      }))
    );
    fetchProviders(selectedTab);
  };

  const createNewRequest = () => {
    setSelectedOrganization(undefined);
    setShow(true);
  };
  const handleView = (organization: Organization) => {
    setSelectedOrganization(organization);
    setShow(true);
  };

  const onDelete = async (id: string) => {
    try {
      await axios.delete(`/api/organization/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      setOrganizations(organizations.filter((org) => org._id !== id));
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  interface FormValues {
    _id?: string;
    name: string;
    type: string;
    address: string;
    city: string;
    state: string;
    linked_organizations?: Organization[];
    created_at: Date;
  }
  const onNewOrganizationSubmit = async (data: FormValues) => {
    try {
      await axios.post("/api/organization/new", data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "X-User-Id": user?._id ?? "",
        },
      });
      fetchProviders(data.type);
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CardContainer>
        <SimpleButton
          onClick={createNewRequest}
          children={
            <div className="flex items-center gap-2">
              <Add style={{ fontSize: "1rem" }} /> New Organization
            </div>
          }
          type="button"
          styleString="primary"
        />
        <Tabs tabs={tabs} onChange={handleTabChange} />
        <OrganizationTable
          organizations={organizations}
          handleView={handleView}
        />
      </CardContainer>
      <OverlayModal
        open={show}
        setOpen={setShow}
        content={
          <OrganizationForm
            onSubmit={onNewOrganizationSubmit}
            existingData={selectedOrganization}
            onDelete={onDelete}
          />
        }
      />
    </>
  );
};

export default Organizations;
