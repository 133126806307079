import axios from "axios";

// Create an Axios instance with common settings
const apiClient = axios.create({
  baseURL: "/api", // Set your base API URL
  timeout: 10000, // Set request timeout (optional)
});

// Interceptor for handling token injection
apiClient.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const user = sessionStorage.getItem("user");
    const userId = user ? JSON.parse(user)._id : null;
    if (userId) {
      // Add the X-User-ID header if user ID exists
      config.headers["X-User-Id"] = userId;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor for handling errors globally
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      // Check for specific status codes
      if (error.response.status === 401) {
        // Unauthorized: Possible token expiration
        console.error("Unauthorized. Redirecting to login.");
        window.location.href = "/login"; // Redirect to login page
      } else if (error.response.status === 403) {
        // Forbidden: Insufficient permissions
        console.error("Access denied. You do not have permission.");
        alert("Access denied.");
      } else if (error.response.status >= 500) {
        // Handle server errors
        console.error("Server error:", error.response.status);
      }
    } else if (error.request) {
      // Network error or no response from the server
      console.error("Network error or no response:", error.message);
      alert("Network error. Please try again.");
    } else {
      // Other kinds of errors (e.g., invalid configuration)
      console.error("Axios error:", error.message);
    }

    // Pass the error to the calling code
    return Promise.reject(error);
  }
);

export default apiClient;
