import React, { ReactNode } from 'react';

interface CardContainerProps {
    style?: string;
    children: ReactNode;
}

const CardContainer: React.FC<CardContainerProps> = ({ style, children }) => {
    return (
        <div className='mb-2 rounded-lg bg-white shadow w-full max-w-4xl p-4 flex-grow relative min-h-[600px] overflow-y-auto'>
            {children}
        </div>
    );
};

export default CardContainer;