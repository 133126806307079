import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";
import { useSession } from "../hooks/SessionHook";
import { Message } from "../types/Message";
import { ChatRoom } from "../types/ChatRoom";

interface ChatContextType {
  socket: Socket | null;
  createChatConnection: (accessToken: string, userId: string) => Socket | null;
  closeChatConnection: () => void;
  unreadMessages: { [key: string]: number };
  setUnreadMessages: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  showChat: boolean;
  setShowChat: React.Dispatch<React.SetStateAction<boolean>>;
  selectedChat: ChatRoom | null;
  setSelectedChat: React.Dispatch<React.SetStateAction<ChatRoom | null>>;
  usersChats: ChatRoom[] | null;
  selectChatById: (chatId: string) => void;
  loadingChats: boolean;
  setChatQueryString: React.Dispatch<React.SetStateAction<string>>;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const createChatConnection = (
  accessToken: string,
  userId: string
): Socket | null => {
  const storedSessionID = sessionStorage.getItem("sessionID");

  if (!accessToken) {
    console.error("Access token is required to create a socket connection.");
    return null;
  }

  const socket: Socket = io(
    process.env.REACT_APP_SOCKET_URL || "http://localhost:4000",
    {
      auth: {
        token: `Bearer ${accessToken}`,
        sessionID: storedSessionID || undefined,
        userID: userId,
      },
      transports: ["websocket"],
      withCredentials: true,
    }
  );

  socket.on("connect", () => {
    const currentSessionID = socket.id;
    if (currentSessionID) {
      sessionStorage.setItem("sessionID", currentSessionID);
    }
  });

  socket.on("new_notification", (notification) => {
    console.log("New notification: ", notification);
  });

  socket.on("disconnect", () => {
    sessionStorage.removeItem("sessionID");
  });

  return socket;
};

export const closeChatConnection = (socket: Socket | null) => {
  socket?.disconnect();
  sessionStorage.removeItem("sessionID");
};

export const ChatProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const { user } = useSession();

  const [socket, setSocket] = useState<Socket | null>(null);

  interface UnreadMessage {
    [key: string]: number;
  }

  const [unreadMessages, setUnreadMessages] = useState<UnreadMessage>({});

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (token && user && !socket) {
      const newSocket = createChatConnection(token, user._id);
      setSocket(newSocket);
    }

    if (socket && user) {
      socket.emit("request_unread_messages", user._id);
      socket.on("fetch_unread_messages", (data: UnreadMessage) => {
        setUnreadMessages(data);
      });

      socket.on(
        "received_message",
        (data: { chatRoomId: string; message: Message }) => {
          // If the message is sent by the current user, do not increment the unread message count
          if (data.message.author._id === user?._id) {
            return;
          }

          setUnreadMessages((prevUnreadMessages) => {
            const newUnreadMessages = { ...prevUnreadMessages };
            if (data.chatRoomId in newUnreadMessages) {
              newUnreadMessages[data.chatRoomId] += 1;
            } else {
              newUnreadMessages[data.chatRoomId] = 1;
            }
            return newUnreadMessages;
          });
        }
      );

      // socket.emit("request_user_chats", user._id);
      socket.on("fetch_user_chats", (chats: ChatRoom[]) => {
        console.log("Fetched user chats: ", chats);
        setUsersChats(chats);
        setLoadingChats(false);
      });

      socket.on("fetch_chat_room", (chatRoom: ChatRoom) => {
        setSelectedChat(chatRoom); // Update the selected chat room with fetched data
      });
    }

    return () => {
      if (socket) {
        closeChatConnection(socket);
        setSocket(null);
      }
    };
  }, [socket, user]);

  const [showChat, setShowChat] = useState(() => {
    const savedShowChat = sessionStorage.getItem("showChat");
    return savedShowChat !== null ? JSON.parse(savedShowChat) : true;
  });

  useEffect(() => {
    sessionStorage.setItem("showChat", JSON.stringify(showChat));
  }, [showChat]);

  const [selectedChat, setSelectedChat] = useState<ChatRoom | null>(null);

  const selectChatById = (chatId: string) => {
    if (socket) {
      socket.emit("request_chat_room", chatId);
    }
  };

  const [usersChats, setUsersChats] = useState<ChatRoom[] | null>([]);
  const [chatQueryString, setChatQueryString] = useState<string>("");
  const [loadingChats, setLoadingChats] = useState<boolean>(true);

  useEffect(() => {
    if (socket && user) {
      setLoadingChats(true);
      socket.emit("request_user_chats", user._id, chatQueryString);
    }
  }, [socket, user, chatQueryString, selectedChat]);

  return (
    <ChatContext.Provider
      value={{
        socket,
        createChatConnection: (accessToken: string, userId: string) => {
          if (socket) {
            closeChatConnection(socket);
          }
          const newSocket = createChatConnection(accessToken, userId);
          setSocket(newSocket);
          return newSocket;
        },
        closeChatConnection: () => {
          if (socket) {
            closeChatConnection(socket);
            setSocket(null);
          }
        },
        unreadMessages,
        setUnreadMessages,
        showChat,
        setShowChat,
        selectedChat,
        setSelectedChat,
        usersChats,
        selectChatById,
        loadingChats,
        setChatQueryString,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useSocket = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useSocket must be used within a ChatProvider");
  }
  return context;
};
