import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ServiceRequest } from "../../types/ServiceRequest";
import { formatDate } from "../../utils/Format";
import SimpleInput from "../general-components/Input";
import SimpleButton from "../general-components/Button";

type AcceptProps = {
  selectedRequest: ServiceRequest;
  onSubmit: (data: FormValues) => void;
};
interface FormValues {
  requestId: string;
  start_date: Date;
  decision: string;
}

const AcceptContent: React.FC<AcceptProps> = ({
  selectedRequest,
  onSubmit,
}) => {
  const discharge_date = formatDate(
    selectedRequest.resident.discharge_date,
    "MM/DD/YYYY",
    "utc"
  );

  const schema = yup.object().shape({
    start_date: yup
      .date()
      .min(discharge_date, "Start Date must be after Discharge Date")
      .required("Start Date is required"),
    decision: yup.string().required("Decision is required"),
    requestId: yup.string().required("Request ID is required"),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      requestId: selectedRequest._id,
      decision: "ACCEPTED",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const startDate = watch("start_date");

  return (
    <form className="flex flex-col gap-2" noValidate onSubmit={handleSubmit(onSubmit)}>
      <h2 className=" text-gray-700 text-sm mr-10">
        To accept the discharge, select a service start date and click accept.
      </h2>
      <SimpleInput
        type="date"
        label="Start Date"
        register={register}
        fieldName="start_date"
        error={errors.start_date?.message}
      />
      {selectedRequest && (
        <SimpleButton
          type="submit"
          styleString="primary"
          children="Accept"
          overrideStyles="w-full"
          disabled={Object.keys(errors).length > 0 || !startDate}
        />
      )}
    </form>
  );
};

export default AcceptContent;
