import React from "react";
import { formatDate } from "../../utils/Format";
import {
  Close,
  Search,
  SearchOffOutlined,
} from "@mui/icons-material";
import { useSocket } from "../../context/ChatContext";
import { useSession } from "../../hooks/SessionHook";
import Spinner from "../general-components/Spinner";

const ChatList: React.FC = ({}) => {
  const {
    setShowChat,
    setSelectedChat,
    usersChats,
    loadingChats,
    setChatQueryString,
    unreadMessages,
  } = useSocket();
  const { currentOrganization } = useSession();

  return (
    <div className="w-full mb-8">
      <div className="border-b px-6 h-16 flex items-center justify-between">
        <h2 className="text-lg font-medium font-dmsans">Chats</h2>
        <button onClick={() => setShowChat(false)}>
          <Close className="cursor-pointer" />
        </button>
      </div>
      <div className="px-6 my-6  flex flex-col h-full">
        <div className="relative my-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <Search
              aria-hidden="true"
              fontSize="small"
              className=" text-grayscale-400"
            />
          </div>
          <input
            placeholder="Search"
            className="form-input block w-full rounded-md border-0 py-1.5 pl-10 text-text-400 ring-1 ring-inset ring-grayscale-300 placeholder:text-text-300 focus:ring-2 focus:ring-inset focus:ring-as1 sm:text-sm sm:leading-6"
            onChange={(e) => setChatQueryString(e.target.value)}
          />
        </div>

        {loadingChats ? (
          <div className="flex flex-col gap-4 bg-grayscale-00 rounded h-40 items-center justify-center">
            <Spinner />
            <p className="font-montserrat text-sm text-grayscale-500">
              Searching for chats...
            </p>
          </div>
        ) : (
          <ul className="divide-y divider-gray-100 border-b flex flex-col mb-20 overflow-y-auto scrollbar-thin pr-2">
            {usersChats && usersChats.length > 0 ? (
              usersChats.map((chat) => (
                <li
                  key={chat._id}
                  onClick={() => setSelectedChat(chat)}
                  className="py-2 px-1 flex w-full justify-between hover:bg-grayscale-200 hover:cursor-pointer "
                >
                  <div className="flex px-1">
                    <div className="flex justify-center items-center w-10 h-10 bg-s1-100 text-white rounded-full mr-2">
                      {chat.service_request?.resident.first_name[0] +
                        chat.service_request?.resident.last_name[0]}
                    </div>
                    <div className="flex flex-col">
                      {currentOrganization &&
                      currentOrganization.type === "NURSING" ? (
                        <span className="font-semibold truncate">
                          {chat.service_request.provider_organization?.name ??
                            "Unknown Organization"}
                        </span>
                      ) : (
                        <span className="font-semibold truncate max-w-40">
                          {chat.service_request.nursing_organization?.name ??
                            "Unknown Organization"}
                        </span>
                      )}
                      {chat.messages[0] && (
                        <div className="text-sm text-text-300 truncate max-w-40">
                          {chat.messages[0].author.given_name}{" "}
                          {chat.messages[0].author.family_name}:{" "}
                          {chat.messages[0]?.content}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-end h-full justify-between">
                    <span className="text-xs text-text-400">
                      {formatDate(chat.messages[0]?.createdAt, "chat")}
                    </span>
                    {/* <ChatIcon chatId={chat._id} /> */}

                    {unreadMessages[chat._id] !== undefined &&
                      unreadMessages[chat._id] > 0 && (
                        <span className="flex font-sans mt-2 p-1 items-center justify-center text-p1 bg-ap1 rounded-full h-6 w-6">
                          {unreadMessages[chat._id]}
                        </span>
                      )}
                  </div>
                </li>
              ))
            ) : (
              <div className="flex flex-col gap-4 bg-grayscale-00 rounded h-40 items-center justify-center">
                <SearchOffOutlined
                  fontSize="large"
                  className="text-grayscale-400"
                />
                <p className="font-montserrat text-sm text-grayscale-500">
                  No results found
                </p>
              </div>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ChatList;
