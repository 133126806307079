import moment from "moment";

export function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

// Function to manipulate date based on direction of calendar
export const changeDateHelper = (
  date: string | Date,
  direction: "forward" | "backward" | "reset"
) => {
  const dateObject = new Date(date);

  let newCurrentDate = moment(dateObject).format("YYYY-MM-DD");
  let startDate = moment(dateObject).format("YYYY-MM-DD");
  let endDate = moment(dateObject).format("YYYY-MM-DD");

  switch (direction) {
    case "backward":
      newCurrentDate = moment(dateObject)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      startDate = moment(newCurrentDate).startOf("month").format("YYYY-MM-DD");
      endDate = moment(newCurrentDate).endOf("month").format("YYYY-MM-DD");
      break;
    case "forward":
      newCurrentDate = moment(newCurrentDate)
        .add(1, "month")
        .format("YYYY-MM-DD");
      startDate = moment(newCurrentDate).startOf("month").format("YYYY-MM-DD");
      endDate = moment(newCurrentDate).endOf("month").format("YYYY-MM-DD");
      break;
    case "reset":
      newCurrentDate = moment().format("YYYY-MM-DD");
      startDate = moment().startOf("month").format("YYYY-MM-DD");
      endDate = moment().endOf("month").format("YYYY-MM-DD");
      break;
  }

  return { newCurrentDate, startDate, endDate };
};

export const debounce = (func: () => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return () => {
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear the previous timeout if called within the delay
    }
    timeoutId = setTimeout(() => {
      func(); // Call the function after the delay
    }, delay);
  };
};
