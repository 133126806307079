import React, { useEffect } from "react";
import ServiceRequestTable from "../components/tables/ServiceRequestTable";
import CardContainer from "../components/container/CardContainer";
import { useProvider } from "../hooks/ProviderHook";
import PageHeader from "../components/general-components/PageHeader";
import FilterComponent from "../components/general-components/Filter";
import ActiveRequestTable from "../components/tables/ActiveRequestTable";
import RejectedRequestTable from "../components/tables/RejectedRequestTable";
import TerminatedRequestTable from "../components/tables/TerminatedRequestTable";

const Services: React.FC = () => {
  const {
    filters,
    handleFilterChange,
    status,
    initializeData,
    serviceRequests,
    linkedFacilities,
  } = useProvider();

  useEffect(() => {
    initializeData(status);
  }, []);

  const filterOptions = [
    {
      name: "resident",
      label: "Resident",
      options: serviceRequests.map((serviceRequest) => ({
        value: serviceRequest._id,
        label:
          serviceRequest.resident.first_name +
          " " +
          serviceRequest.resident.last_name,
      })),
      searchable: true,
    },
    {
      name: "nursing_organization",
      label: "Referral Source",
      options: linkedFacilities.map((organization) => ({
        value: organization._id,
        label: organization.name,
      })),
      searchable: true,
    },
    {
      name: "discharge_date",
      label: "Discharge Date",
      options: [
        { value: "today", label: "Today" },
        { value: "this-week", label: "This Week" },
        { value: "this-month", label: "This Month" },
        { value: "this-year", label: "This Year" },
      ],
      searchable: false,
    },
    {
      name: "assigned_to",
      label: "Case Manager",
      options: [
        { value: "1", label: "John Doe" },
        { value: "2", label: "Jane Doe" },
        { value: "3", label: "John Smith" }
      ],
      searchable: false,
    },
  ];

  const header =
    status === "pending"
      ? "Pending Requests"
      : status === "accepted"
      ? "Active Referrals"
      : status === "terminated"
      ? "Terminated Referrals"
      : status === "rejected"
      ? "Rejected Referrals"
      : "";

  return (
    <>
      <CardContainer>
        <PageHeader title={header} />
        <div className="my-2">
          <FilterComponent
            filterOptions={filterOptions}
            filters={filters}
            handleFilterChange={handleFilterChange}
          />
        </div>
        {status === "pending" ? (
          <ServiceRequestTable />
        ) : status === "accepted" ? (
          <ActiveRequestTable />
        ) : status === "rejected" ? (
          <RejectedRequestTable />
        ) : status === "terminated" ? (
          <TerminatedRequestTable />
        ) : null}
      </CardContainer>
    </>
  );
};

export default Services;
