import React from "react";

interface PageHeaderProps {
  title: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return <div className="flex w-full font-semibold font-montserrat">{title}</div>;
};

export default PageHeader;
