// components/DischargeTable.tsx

import React, { useState } from "react";
import Badge from "../general-components/Badges";
import { formatDate } from "../../utils/Format";
import { Resident } from "../../types/Resident";
import Table from "./Table";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { User } from "../../types/User";

interface DischargeTableProps {
  residents: Resident[];
  handleView: (resident: Resident, route: string) => void;
  handleLoadMore: () => void;
  isMoreItems: boolean;
  users?: User[];
  loading: boolean;
}

const DischargeTable: React.FC<DischargeTableProps> = ({
  residents,
  handleView,
  handleLoadMore,
  isMoreItems,
  loading,
}) => {
  const types = ["CHHA", "LHCSA", "DME", "RPM", "PHARMACY"];

  const columns = [
    {
      header: "Resident Name",
      render: (resident: Resident) => (
        <>
          <span className=" text-gray-900">
            {resident.first_name} {resident.last_name}
          </span>
          <dl className="font-normal sm:hidden">
            <dt className="sr-only">Discharge Date</dt>
            <dd className="mt-1 truncate text-gray-700">
              {formatDate(resident.discharge_date, "MM/DD/YYYY", "utc")},
            </dd>
            <dt className="sr-only">Statuses</dt>
            <dd className="mt-1 text-gray-700 flex flex-col gap-2 w-fit">
              {types.map((type) => {
                const service = resident?.service_requests?.find(
                  (item) => item.service_type === type
                );
                return (
                  <Badge
                    key={service ? service._id : type}
                    badge={{
                      name: service ? service.status : "Not Started",
                      status: service ? service.status : "Not Started",
                    }}
                    display={type}
                  />
                );
              })}
            </dd>
          </dl>
        </>
      ),
      className: "w-full max-w-0 py-4 pl-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      header: "Assigned to",
      render: (resident: Resident) => {
        if (
          !resident.discharge_coordinators ||
          resident.discharge_coordinators.length === 0
        ) {
          return (
            <div key={resident._id} className="flex items-center gap-2">
              <div className="flex justify-center items-center w-6 h-6 bg-gray-200 text-white rounded-full">
                U
              </div>
              <span>Unassigned</span>
            </div>
          );
        }

        return (
          <div>
            {resident.discharge_coordinators?.map((coordinator) => {
              return (
                <div
                  key={coordinator?.user._id}
                  className="flex items-center gap-2"
                >
                  <div className="flex justify-center items-center w-6 h-6 bg-s1-100 text-white rounded-full">
                    {coordinator?.user?.given_name.charAt(0).toUpperCase()}
                  </div>
                  <span>
                    {coordinator?.user?.given_name}{" "}
                    {coordinator?.user?.family_name}
                  </span>
                </div>
              );
            })}
          </div>
        );
      },
      className: "text-left pr-0",
    },
    {
      header: "Status",
      render: (resident: Resident) => {
        return (
          <Badge
            badge={{
              name: "Overall Status",
              status: resident.overall_status ?? "Not Started",
            }}
            display={resident.overall_status ?? ""}
          />
        );
      },
      className: "hidden md:table-cell",
    },
    {
      header: "Discharge Date",
      render: (resident: Resident) =>
        resident.discharge_date
          ? formatDate(resident.discharge_date, "MM/DD/YYYY", "utc")
          : "N/A",
      className: "hidden sm:table-cell",
    },
    {
      header: "",
      render: () => <ExpandLess className="rotate-90" />,
      className: "text-left pr-0",
    },
  ];

  return (
    <Table
      data={residents}
      columns={columns}
      noDataMessage={loading ? "Loading..." : "No Matching Discharges"}
      handleLoadMore={handleLoadMore}
      isMoreItems={isMoreItems}
      onRowClick={(item) => handleView(item, window.location.pathname)}
    />
  );
};

export default DischargeTable;
