import React from "react";
import { capitalizeString } from "../../utils/Format";
import classNames from "classnames";

interface UserProps {
  _id: string;
  given_name: string;
  family_name: string;
  index?: number;
}

const InitialBadge: React.FC<UserProps> = ({
  _id,
  given_name,
  family_name,
  index = 0,
}) => {

  const backgroundColors = ["s1-100", "ap1-500"];
//   If index is less than 0, set the backgroundColor to "gray-500"
  const backgroundColor = backgroundColors[index % backgroundColors.length] || "gray-500";

  return (
    <div key={_id} className="flex items-center gap-2">
      <div
        className={classNames(
          "flex justify-center items-center w-6 h-6 text-white rounded-full",
          `bg-${backgroundColor}`
        )}
      >
        {capitalizeString(given_name).charAt(0)}
      </div>
      <span>
        {capitalizeString(given_name)} {capitalizeString(family_name)}
      </span>
    </div>
  );
};

export default InitialBadge;