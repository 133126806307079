import React from "react";
import * as MuiIcons from "@mui/icons-material";
import classNames from "classnames";

export type ButtonTypes =
  | "primary"
  | "secondary"
  | "danger"
  | "minimal"
  | "bold";

type MuiIconNames = keyof typeof MuiIcons;

type ButtonProps = {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: (e: any) => void;
  styleString?: ButtonTypes;
  children: React.ReactNode;
  overrideStyles?: string;
  icon?: MuiIconNames;
};

const SimpleButton: React.FC<ButtonProps> = ({
  type = "button",
  disabled,
  onClick,
  styleString = "primary",
  children,
  overrideStyles,
  icon,
}) => {
  const buttonStyle = classNames(
    "flex justify-center items-center w-fit rounded px-3.5 py-2 text-sm font-semibold",
    {
      "bg-ap1-400 text-text-100 border border-transparent shadow-sm hover:bg-ap1-500":
        styleString === "primary",
      "bg-ap2-400 text-text-100 border border-transparent shadow-sm hover:bg-ap2-500":
        styleString === "bold",
      "bg-transparent text-ap2-400 border border-ap2-400 shadow-sm":
        styleString === "secondary",
      "bg-error text-text-100 shadow-sm hover:bg-error-600":
        styleString === "danger",
      "bg-transparent text-ap2-400": styleString === "minimal",
      "bg-blue-500 text-white": ![
        "primary",
        "bold",
        "secondary",
        "danger",
        "minimal",
      ].includes(styleString),
      "cursor-not-allowed bg-grayscale-300 hover:bg-grayscale-300 text-grayscale-600 hover:cursor-disabled":
        disabled,
      "hover:cursor-pointer": !disabled,
    },
    overrideStyles
  );

  const IconComponent = icon ? MuiIcons[icon as MuiIconNames] : null;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={buttonStyle}
    >
      <div className="flex gap-2 items-center">
        {IconComponent && (
          <IconComponent className="mr-1" style={{ fontSize: "1rem" }} />
        )}
        {children}
      </div>
    </button>
  );
};

export default SimpleButton;
