import { Resident } from "../../types/Resident";
import apiClient from "../apiClient";

// Fetch a list of residents with query parameters
export const fetchResidents = async (
  organizationId: string,
  status: string[],
  params: any
) => {
  try {
    const response = await apiClient.get(
      `/referrals/residents/table/${organizationId}`,
      {
        params: {
          residentStatus: status || ["ACTIVE"],
          ...params,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching residents", error);
    return { residents: [], hasMore: false };
  }
};

interface ResidentFormValues {
  discharge_date: Date;
  resident_id: string;
  first_name: string;
  last_name: string;
  dob: string;
}
// Create a new resident
export const createResident = async (
  organizationId: string,
  userId: string,
  residentData: ResidentFormValues
) => {
  try {
    const response = await apiClient.post("/referrals/resident/new", {
      ...residentData,
      organization: organizationId,
      userId,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating resident", error);
    throw error;
  }
};

export const fetchUsers = async (organizationId: string) => {
  try {
    const response = await apiClient.get(
      `/referrals/users?userId=${organizationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching users", error);
    return [];
  }
};

export const fetchDocument = async (residentId: string, fileType: string) => {
  try {
    const response = await apiClient.get(
      `/referrals/document?residentId=${residentId}&fileType=${fileType}`,
      {
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(response.data);
    window.open(url);
  } catch (error) {
    console.error("Error fetching the document:", error);
  }
};

export const fetchExternalResident = async (serviceRequestId: string) => {
  try {
    const response = await apiClient.get(
      `/services/resident?requestId=${serviceRequestId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching external resident:", error);
  }
};
