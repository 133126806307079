import { formatDate } from "../../utils/Format";
import { ExpandLess } from "@mui/icons-material";
import { ServiceRequest } from "../../types/ServiceRequest";
import Table from "./Table";
import { useProvider } from "../../hooks/ProviderHook";
import InitialBadge from "../general-components/InitialBadge";
import UserSelector from "../general-components/UserSelector";

interface ServiceRequestTableProps {}

export default function ServiceRequestTable({}: ServiceRequestTableProps) {
  const {
    serviceRequests,
    loading,
    handleView,
    handleLoadMore,
    status,
    moreItems,
  } = useProvider();

  const columns = [
    {
      header: "Resident Name",
      render: (serviceRequest: ServiceRequest) => (
        <>
          <span className=" text-gray-900">
            {serviceRequest.resident.first_name}{" "}
            {serviceRequest.resident.last_name}
          </span>
        </>
      ),
      className: "w-full max-w-0 py-4 pl-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      header: "Referral Source",
      render: (serviceRequest: ServiceRequest) => (
        <>
          <span className=" text-gray-900">
            {serviceRequest.nursing_organization.name}
          </span>
        </>
      ),
      className: "w-full max-w-0 py-4 pl-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      header: "Discharge Date",
      render: (serviceRequest: ServiceRequest) =>
        serviceRequest.resident.discharge_date
          ? formatDate(
              serviceRequest.resident.discharge_date,
              "MM/DD/YYYY",
              "utc"
            )
          : "N/A",
      className: "hidden sm:table-cell",
    },
    {
      header: "Assigned To",
      render: (serviceRequest: ServiceRequest) => (
        <UserSelector assignedToId={serviceRequest?.assigned_to?._id ?? "0"} />
      ),
      className: "hidden sm:table-cell",
    },
    {
      header: "",
      render: () => <ExpandLess className="rotate-90" />,
      className: "text-left pr-0",
    },
  ];

  return (
    <Table
      columns={columns}
      data={serviceRequests}
      noDataMessage={loading ? "Loading..." : "No Matching Discharges"}
      handleLoadMore={() => handleLoadMore(status)}
      isMoreItems={moreItems}
      onRowClick={(item) => handleView(item._id, window.location.pathname)}
    />
  );
}
